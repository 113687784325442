@import "../../styles/helpers";

.section {
    padding: 0;
    @include x {
        padding: 80px 0; }
    @include m {
        padding: 64px 0; } }

.container {
    width: 100%; }

.bg {
    position: relative;
    flex-grow: 1;
    margin-right: 96px;
    @include x {
        margin-right: 64px; }
    @include d {
        margin-right: 32px; }
    @include t {
        margin: 0 0 32px; } }

.details {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 384px;
    @include d {
        width: 336px; }
    @include t {
        width: 100%; } }
.dropdown {
    margin-left: auto; }
.heading {
    display: flex;
    width: 100%;
    margin-bottom: 20px; }
.field {
    font-size: 18px;
    font-weight: 600; }
.value {
    font-size: 15.3px;
    font-weight: 400;
    margin-bottom: 20px;
    color: grey; }
.title {
    font-size: 25px;
    font-weight: bold;
    margin-left: 20px; }
.createButton {
    margin-left: auto;
    position: relative;
    @include m {
        position: static; } }

.card {
    width: 100%;
    padding: 50px !important;
    background-color: #fff;
    margin: 0 10px;
    border-radius: 10px;
    box-shadow: 0px 16px 64px -16px rgba(31, 47, 70, 0.3);
    height: fit-content; }

.preview {
    position: relative;
    height: 100%;
    border-radius: 16px;
    img,video {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        margin-bottom: 20px; } }


.icon {
    flex-shrink: 0;
    width: 20px;
    margin-right: 8px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; } }

.text {
    margin-right: auto; }

.control {
    margin-top: 40px;
    @include m {
        margin-top: 32px; } }

.tags {
    margin-bottom: 20px; }

.tag {
    background-color: #e6e6e6;
    border-radius: 30px;
    padding: 3px 16px 6px 16px;
    color: #4f5566;
    border: none;
    margin: 5px 0 25px 10px; }


.description {
    font-size: 14px;
    margin: 10px 0; }

.edition {
    font-weight: 600;
    font-size: 16px;
    color: #000;
    @include dark {
        color: $neutrals6; } }

.btns {
    display: flex;
    flex-direction: column;
    .button {
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 8px; } } }

.loader {
    margin: 10% 50%; }

.search {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    margin-right: 24px;
    @include t {
        display: none; }
    @include m {
        display: block;
        width: 100%;
        margin: auto 0 0; } }

.input {
    width: 100%;
    height: 40px;
    padding: 0 42px 0 16px;
    background: none;
    border: 2px solid $neutrals6;
    border-radius: 8px;
    @include poppins;
    @include caption-2;
    transition: border-color .2s;
    @include placeholder {
        color: $neutrals4; }
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 42px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $cyan; } } }

.allComments {
    width: 100%;
    margin: 5px 0;
    padding: 5px; }

.commentItem {
    display: flex; }

.user {
    height: 45px;
    width: 45px;
    border-radius: 50%; }
.text {
    margin-top: 10px;
    margin-left: 20px; }
.load {
    text-align: right;
    cursor: pointer;
    &:hover {
        color: #292ff9; } }
