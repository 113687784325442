@import "../../styles/helpers";

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals5; }

.textarea {
    width: 100%;
    height: 96px;
    padding: 10px 14px;
    resize: none;
    border-radius: 12px;
    border: 2px solid $neutrals6;
    background: none;
    @include poppins;
    @include caption-bold-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    @include placeholder {
        color: $neutrals4; }
    &:focus {
        border-color: $neutrals4; } }
.textarea1 {
    width: 100%;
    height: 96px;
    padding: 10px 14px;
    resize: none;
    border-radius: 12px;
    border: 2px solid $neutrals6;
    background: none;
    @include poppins;
    @include caption-bold-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    @include placeholder {
        color: $neutrals4; }
    &:focus {
        border-color: $neutrals4; }

    width: 100%;
    height: 96px;
    padding: 10px 14px;
    resize: none;
    border-radius: 12px;
    border: 2px solid #E6E8EC;
    background: none;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1.71429;
    font-weight: 500;
    color: #23262F;
    transition: border-color .2s; }
