[class^="button"] {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    background: #4F42C9;
    @include redhat;
    // background-image: linear-gradient(to right, #FF3366 , #FFB14A, #6666FF);
    border-radius: 24px;
    @include button-1;
    text-align: center;
    color: white;
    transition: all .2s;
    &:hover {
        color: white;
        background: #f1535e;
        // color: black
        box-shadow: 5px 5px 30px 0px #cec4c4;
 }        // background-image: linear-gradient(to right, #6666FF , #FFB14A, #FF3366 );

    &.disabled {
        opacity: .5;
        pointer-events: none; }
    &.done {
        background: #BE2053; }
    &.loading {
        background: darken($cyan, 15);
        font-size: 0; }
    &.error {
        box-shadow: 0 0 0 2px #35bebf inset;
        background: none;
        color: $pink;
        @include dark-common {
            box-shadow: 0 0 0 2px $neutrals4 inset; } }
    svg {
        fill: white; }
    &:not([class^="button-circle"]) {
        svg {
            &:first-child {
                margin-right: 15px; }
            &:last-child {
                margin-left: 15px; } } } }

.button-stroke {
    background: white;
    // box-shadow: 0 0 0 0.5px #4F42C9 inset
    border: 1px solid #4F42C9;
    @include redhat;
    color: black;
    svg {
        fill: $neutrals2;
        transition: fill .2s; }
    &:hover {
        background: #f1535e;
        // box-shadow: 5px 5px 30px 0px #cec4c4
        border: 1px solid #cec4c4;
        color: white;
        svg {
            fill: white; } }
    @include dark-common {
        // box-shadow: 0 0 0 2px $neutrals3 inset
        border: 1px solid $neutrals3;
        color: white;
        svg {
            fill: white; }
        &:hover {
            box-shadow: 0 0 0 2px $cyan inset; } } }

.button-pink {
    background: $pink;
    &:hover {
        background: darken($pink, 12); } }

.button-small {
    height: 40px;
    border-radius: 20px;
    padding: 0 16px;
    font-size: 14px; }

.button-circle-stroke {
    flex: 0 0 48px;
    width: 48px;
    height: 48px;
    padding: 0;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #4F42C9  inset;
    background: transparent;
    transition: all .2s;
    svg {
            fill: $neutrals4;
            transition: fill .2s; }
    &:hover {
            background: #f1535e;
            box-shadow: 0 0 0 2px #f1535e  inset;
            svg {
                    fill: white; } }
    @include dark-common {
        box-shadow: 0 0 0 2px $neutrals3 inset;
        &:hover {
            background: $neutrals3; } } }

.button-circle-stroke.button-small {
    flex: 0 0 40px;
    width: 40px;
    height: 40px; }



