@import "../../../styles/helpers";

.options {
    display: flex;
    padding: 3px;
    border-radius: 32px;
    background: $neutrals8;
    width: 36%;
    // margin-top: 22px
    justify-content: space-around;
    @include dark {
        background: $neutrals2; } }

.button {
    margin-top: 5px;
    &:not(:last-child) {
        margin-right: 24px; } }


.favorite {
    svg {
        fill: #3CC5C5; }
    // &:hover
    //     svg
 }    //         fill: $pink
