@import "../../../styles/helpers";

.container {
    width: 100%;
    max-width: 1480px;
    margin: 0 auto;
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 5%;
    @include d {
     padding-left: 30px; }
    @include m {
     padding-left: 80px; }
    // +w
    //     width: 100%
    // +d
    //     width: 100%
    // +t
    //     padding: 0 40px
    // +m
    //     margin: auto
 }    //     padding: 10px 50px 0

.line {
    display: block;
    border: solid 2px;
    color: #EE2151;
    width: 100px;
    height: 2px;
    background: #EE2151;
    border-left: none;
    border-left-color: currentcolor;
    border-right: none;
    border-right-color: currentcolor;
    margin: 18px auto 30px;
    margin-bottom: 50px;
    @include m {
        border-radius: 10px;
        width: 50px;
        margin: -10px auto;
        margin-bottom: 20px;
        transform: translateY(300%); } }

.design {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    margin: 30px  70px;
    @include m {
      margin: 22px 5px;
      display: flex;
      flex-direction: column;
      align-items: center; } }



.title {
    @include redhat;
    text-align: center;
    color:  black;
    margin-bottom: 10px;
    @include dark {
        color:  white; }

    @include m {
      font-size: 16px; } }

.title1 {
    @include redhat;
    color:  black;
    font-size: 16px;
    @include d {
      font-size: 13px; }

    @include m {
      font-size: 13px;
      text-align: center; } }



.avatarWrapper {
  position: relative;
  width: 60px;
  height: 60px; }

.avatar {
  vertical-align: middle;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  @include d {
        height: 60px;
        width: 60px; }
  @include m {
        height: 60px;
        width: 60px; } }

.icon {
  position: absolute;
  right: 2px;
  bottom: 4px;
  border-radius: 50%;
  background: #EE2151;
  width: 15px;
  height: 15px;
  display: grid;
  text-align: center;
  line-height: 15px;
  color: white;
  font-size: 10px;
  font-weight: bolder; }

.row {
    // borderRadius:   10px
    // --bs-gutter-x: 1.5rem;
    // --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 5%;
    gap: 5%;
    @include t {
       margin-left: -7%; }
    @include m {
        gap: 0%;
        display: flex; } }
