@import "../../styles/helpers";

.card {
    background: white !important;
    @include redhat;
    cursor: pointer;
    flex-basis: calc(25% - 32px);
    max-width: calc(65% - 32px);
    margin: 32px 14px 0px;
    display: flex;
    flex-direction: column;
    @include m {
        margin-bottom: 2px; }
    @include d {
        flex-basis: calc(33.33% - 32px);
        max-width: calc(33.33% - 32px); }
    @include m {
        flex-basis: calc(50% - 32px);
        max-width: calc(50% - 32px); }
    @include a {
        flex-basis: calc(100% - 32px);
        max-width: calc(100% - 32px); } }
.preview {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    height: 100%;
    & > img,video {
        width: 100%;
        height: 100%;
        transition: 1s all ease-in-out; } }
.preview:hover {
    & > img {
        transform: scale(1.3); } }
.control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $neutrals3;
    visibility: hidden;
    border-radius: 16px;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: $neutrals8; } }

.category {
    position: absolute;
    top: 11px;
    left: 8px; }

.favorite {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background: $neutrals8;
    box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2);
    border-radius: 50%;
    svg {
        width: 20px;
        height: 20px;
        fill: $neutrals4;
        transition: fill .2s;
        transition: opacity .2s; }
    &:before {
        content: "";
        position: absolute;
        top: 51%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 18px;
        height: 18px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        opacity: 0;
        transition: opacity .2s; }
    &:hover {
        svg {
            fill: $neutrals2; } }
    &.active {
        svg {
            opacity: 0; }
        &:before {
            opacity: 1; } }
    @include dark {
        background: $neutrals2;
        &:hover {
            svg {
                fill: $neutrals8; } } } }

.button {
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%); }

.link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 0;
    color: $neutrals2;
    @include dark {
        color: $neutrals8; } }

//  "relative max-w-6xl mx-auto  text-center"
.body {
    position: relative; }

.avatar {
    margin: auto;
    width: 100%;
    // height: 252px
    margin: 0 auto;
    border-radius: 20px 20px 0 0;
    object-fit: cover; }

.collection {
    display: flex;
    justify-content: center; }

.card:hover {
    opacity: 0.5;
 }    // box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2)

.cardcontainer {
    @include redhat;
    // padding: 2px 16px
    font-size: 30px;
    text-align: center;
    // color: #ffffff
    margin: 0 auto;
    border: 1px solid #ddd;
    // background-color: #0586AE
    padding: 10px;
    border-radius: 0 0 20px 20px;
    // background-image: linear-gradient(180deg, #0586AE, #111111)
    p {
        font-size: 13px;
        color: black;
        margin: 5px 0 0 0;
        text-align: center;
        line-height: 1.2; } }
.title {
    @include redhat;
    margin-top: 15px;
    font-weight: 600;
    font-size: 16px;
    color: black; }

.filter {
    border: 1px solid gray;
    border-radius: 20px;
    padding: 10px; }
.name {
    font-size: 2.25rem;
    font-weight: 500;
    margin-top: 8%;
    margin-bottom: 3%;
    @include m {
        font-size: 1.25rem;
        margin-top: 14%; } }

.showmore:hover {
    background-color: #0586AE;
    color: #ffffff; }
.tranding {
    // z-index: -1
    margin-top: 56px;
    margin-bottom: 56px; }
.revealGif {
    left: 41%;
    top: -28px;
    // bottom: 27%
    object-fit: cover;
    position: absolute;
    height: 50px;
    width: 50px;
    box-shadow: 1px 2px 3px 3px #5c56549c;
    border-radius: 50%; }

.header {
    display: flex;
    justify-content: center;
    gap: 2px; }
.icon {
    margin-top: 15px;
    color: rgb(32, 129, 226);
    font-size: 26px;
    display: flex;
    margin-bottom: 4px; }
.details {
    display: flex;
    flex: row;
    justify-content: center;
    gap: 8px;
    margin-top: 2px; }
.createdBy {
    font-size: 12px;
    color: black;
    white-space: noWrap; }
.verifyIcon {
    font-size: 16px;
    color: rgb(32, 129, 226); }
.loader {
    margin: 68px auto; }
.loader1 {
    // position: absolute;
    // top: -70px;
    // right: 0;
    // left: 0;
    margin: 80px auto 0; }
.loader2 {
    margin: 0 auto; }
