@import "../../styles/helpers";

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 10;
    background-color: white;
    @include t {
        padding: 10px 0 0px;
        border: none; }
    @include m {
        padding: 0; } }


.container {
    display: flex;
    align-items: center;
    padding: 20px 80px;
    @include t {
        padding: 10px 40px; }
    @include m {
        padding: 10px 20px;
        justify-content: space-evenly; }
    & > .button {
        margin-right: 12px;
        @include m {
            display: flex; } } }

.logo {
    position: relative;
    z-index: 12;
    display: inline-block;
    flex-shrink: 0;
    width: 200px;
    @include m {
        width: 50px; } }
.logos {
    position:relative {}
    display:flex {}
    width: 100%;
    @include t {
        display: none; } }
.logos1 {
    display: none;
    position:relative {}
    display:flex {}
    width: 100%;
    @include t {
        display: block;
        width: 33%; }
    @include m {
        width: 100%; } }

.wrapper {
    display: flex;
    align-items: center;
    justify-items: flex-end;
    flex-grow: 1;
    // margin-left: 30
    @include d {
        flex-grow: 1; }
    @include t {
        flex-grow: 1; }
    @include m {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        align-items: stretch;
        // height: 100vh
        margin: 0;
        padding: 100px 32px 40px;
        box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2);
        background: $neutrals8;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        @include dark {
            background: $neutrals1; }
        &.active {
            visibility: visible;
            opacity: 1; } }
    .button {
        display: none;
        @include m {
            display: inline-flex;
            font-size: 13px;
            // margin-top: 16px
            height: 40px;
            width: 70%; } } }

.nav {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-end;
    //gap: 70px
    margin-right: 40px;
    align-items: center;
    @include d {
        display: flex;
        flex-direction: row;
        margin-left: 280px;
        gap: 35px; }

    @include t {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        grid-gap: 62px;
        gap: 20px; }

    @include m {
        display: flex;
        flex-direction: column;
        margin: 0px;
        gap: 40px;
        border: none; }
    @include dark {
        border-color: $neutrals3; } }

.link {
    display: flex;
    font-size: 16px;
    @include redhat;
    font-weight: 400;
    // line-height: 40px
    color: black;
    transition: color .2s;
    margin-left: 10%;
    @include m {
        margin-left: 0;
        font-size: 16px;
 }        // line-height: 64px
    &:hover,
    &.active {
        color:#4F42C9 {} }
    &:nth-child(n+4) {
        display: none;
        @include m {
            width: 50%;
            display: block; } } }
.activeLink {
    color:#4F42C9 {}
    display: flex;
    font-size: 16px;
    @include redhat;
    font-weight: 400;
    transition: color .2s;
    margin-left: 10%;
    @include m {
        margin-left: 0;
        font-size: 16px; }
    &:hover,
    &.active {
        color:#4F42C9 {} }
    &:nth-child(n+4) {
        display: none;
        @include m {
            width: 50%;
            display: block; } } }

.search {
    // position: relative
    // left:   10vh
    flex-shrink: 0;
    width: 256px;
    margin-right: 24px;
    @include t {
        display: none; }
    @include m {
        display: block;
        width: 100%;
        margin: auto 0 0; } }

.input {
    width: 100%;
    height: 30px;
    padding: 0 42px 0 16px;
    background: none;
    border: 1px solid #35bebf;
    border-radius: 8px;
    @include poppins;
    @include caption-2;
    transition: border-color .2s;
    @include placeholder {
        color: $neutrals4; }
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 42px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $cyan; } } }

.notification {
    margin-right: 24px;
    @include m {
        margin-right: 20px; } }

.burger {
    display: none;
    @include m {
        display: block;
        position: relative;
        margin-left: 20px;
        margin-right: 10px;
        width: 10px;
        height: 32px;
        background: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        &.active {
            &:before {
                transform: translateY(0) rotate(-45deg); }
            &:after {
                transform: translateY(0) rotate(45deg); } }
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 16px;
            left: 6px;
            width: 20px;
            height: 2px;
            background: $neutrals4;
            border-radius: 2px;
            transition: transform .2s; }
        &:before {
            transform: translateY(-4px); }
        &:after {
            transform: translateY(3px); } } }
.loginButton {
    width: fit-content;
    padding: 10px 34px;
    height: fit-content;
    @include m {
        padding: 10px 15px; } }
