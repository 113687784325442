.emailText {
  font-weight: bold;
  color: #333;
  padding-left: 450px; }


.buttonContainer {
  display: flex;
  gap: 10px; }

.button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  border-radius: 7px; }

.selected {
  background-color: #007bff;
  color: #fff; }

.content {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 5px; }
