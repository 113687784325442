
.buttonGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; }

.button {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  text-align: center; }

.activeButton {
  background-color: #007bff;
  color: #fff; }

.additionalButtons {
  display: flex;
  flex-direction: column;
  gap: 10px; }

.modalContent {
  width: 400px;
  height: 200px; }

.analyticsData {
  /* Add styling for the date inputs and category checkboxes */
  label {
    display: block;
    margin-bottom: 8px; }

  input[type="checkbox"] {
    margin-right: 6px;
    vertical-align: middle; }

  /* Style the label text next to the checkboxes */
  label {
    input[type="checkbox"] {
      margin-right: 6px;
      vertical-align: middle; }
    &:hover {
      cursor: pointer; } }

  /* Style the "Select All" button */
  button {
    padding: 6px 12px;
    background-color: green;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: green; } }

  .alert {
      text-align: center;
      color: #f44336;
      margin: 16px 0;
      font-size: 12; }

  .success {
      text-align: center;
      color: green;
      margin: 16px 0;
      font-size: 16px; }

  .analyticsData {}
  label {
    display: block;
    margin-bottom: 8px; }

  input[type="date"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px; }

  label {
    input[type="checkbox"] {
      margin-right: 6px;
      vertical-align: middle; } }

  label:hover {
    cursor: pointer; }

  button {
    padding: 6px 12px;
    background-color: blue;
    // display: flex
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: blue; } } }
