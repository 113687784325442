@import "../../styles/helpers";

.container {
    margin-top: 2%;
    font-family: 'RedHatDisplay';
    @include m {
        margin: 80px auto; } }
.banner {
    position: relative;
    inset: 0;
    top: 14px;
    backgrround-color: grey;
    border-radius: 20px;
    margin-bottom: 100px;
 }    // bannerImg-shadow relative inset-0 top-14 bg-gray-50 rounded-xl mb-32 mx-6 lg:mb-16 top-10 sm:mb-48
.bannerImage {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0 0 20px -3px #ababab;
 }    // w-full h-full object-cover absolute rounded-2xl
.logoContainer {
    display: flex;
    text-align: center;
    height: 100%;
    align-items: left;
    @include m {
        margin: 20px auto; } }
.bean {
    font-size: 40px;
    text-align: center;
    color: black; }
.group {
    border-radius: 10px;
    width: 80%; }
.item {
    display: flex;
    margin: 0 auto;
    align-items: center;
    height: 100%;
    @include m {
        padding: 4px;
        display: block; } }

.item1 {
    display: flex;
    width: fit-content;
    border: 1px solid #5048c6;
    border-radius: 10px;
    margin: 0 auto;
    @include m {
        display: none; } }
.item2 {
    font-size: 14px;
    color: black;
    font-family: 'RedHatDisplay';
    border-right: none;
    padding: 11px 19px;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 500;
    white-space: nowrap;
    display: flex;
    &:not(:last-child) {
 }        // border-right: 1px solid #5048c6
    @include m {
        font-size: 14px;
        // border-bottom: 1px solid rgb(229, 232, 235)
 }        // border-right: 0
    // &:hover
    //     box-shadow: 0px 0px 10px 3px #8e89e0
    //     // border: 2px solid rgb(229, 232, 235)
    //     transition: scale(1.5)
    //     cursor: pointer
    // &:first-child
    //     border-top-left-radius: 10px
    //     border-bottom-left-radius: 10px
    // &:nth-child(4)
    //     border-top-right-radius: 10px
 }    //     border-bottom-right-radius: 10px

.item3 {
    @include redhat;
    font-size: 15px;
    color: #343434b8;
    font-weight: bold;
    margin-right: 10px;

    @include m {
        font-size: 12px; } }

.item4 {
    font-size: 38px;
    color: black;
    font-weight: 600;
    font-family: 'RedHatDisplay';
    text-align: center;
    @include m {
        font-size: 16px; }
    p {
        font-size: 16px;
        text-align: center;
        font-weight: normal;
        margin: 10px auto;
        // max-height: 60px
        word-break: break-word;
        // -webkit-mask: linear-gradient(rgb(255, 255, 255) 45%, transparent)
        max-width: 800px;
        transition: max-height 100ms ease 0s;
        @include m {
            font-size: 10px;
            margin: 10px auto 3%;
            width: 500px; } }
    span {
        font-size: 16px;
        text-align: center;
        font-weight: normal;
        margin: 10px auto;
        max-width: 800px;
        @include m {
            font-size: 10px;
 } } }            // margin: 10px auto 36%
.arrow {
    svg {
        margin: 0 auto;
        color: rgb(138, 147, 155); } }
.head {
    display: flex;
    height: 100%;
    align-items: center;
    // justify-content: center
    margin-right: 10px;
    margin-left: 27px;
    @include m {
        text-align: center; } }
.heading {
    @include redhat;
    // font-family: 'RedHatDisplay'
    color: #000000;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 15px;
    display: flex;
    margin: 0 auto;
    align-items: baseline;
    @include m {
        width: 100%;
        margin-top: 10px;
        justify-content: center; } }
.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 0;
    margin-right: 9px;
    @include m {
        text-align: center; } }

.verifyIcon {
    color: rgb(32, 129, 226); }
.creator {
    font-size: 15px;
    color: #343434b8;
    text-align: left;
    font-weight: 500;
    // margin-bottom: 10px
    display: flex;
    font-weight: bold;
    white-space: nowrap;
    svg {
        color: #4981ff; }
    @include m {
        flex-direction: column;
        line-height: 1.2;
        text-align: center; } }
.revealGif {
    // position: absolute
    // left: 0
    // right: 0
    // margin-left: 40px
    // margin-top: -75px
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 5px 30px -3px #262525;
    @include m {
        margin: 0 auto; } }
.flexImg {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @include m {
        display: flex;
        justify-content: center;
        gap: 5px; }
    img {
        // width: 10%
        height: 20px; } }
.activityButton {
    width: fit-content;
    background-color: #090D13;
    color: $white;
    font-family: 'RedHatDisplay';
    padding: 5px 70px;
    border-radius: 25px; }
.collectionButton {
    width: fit-content;
    background-color: #56B5EB;
    color: $white;
    font-family: 'RedHatDisplay';
    padding: 5px 70px;
    border-radius: 25px;
    box-shadow: 0px 0px 15px 1px #56ebeb; }
.watchFlex {
    bottom: -28%;
    right: 0;
    color: black;
    position: absolute;
    display: flex;
    @include m {
        right: 0;
        bottom: -40%; }
    @include a {
        bottom: -82%; } }
.watchlist {
    display: flex;
    align-items: center;
    border: 1px solid #ffc4d6f5;
    padding: 7px 19px;
    border-radius: 10px;
    color: #8C939A;
    margin-right: 10px;
    font-weight: 500;
    cursor: pointer;
    svg {
        color: #8C939A;
        margin-right: 5px; } }
.watchBorder {
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid #ffc4d6f5;
    border-radius: 10px;
    margin: 0 auto; }
.watchBorder1 {
    font-size: 22px;
    color: #8C939A;
    font-family: 'RedHatDisplay';
    border-right: 1px solid #ffc4d6f5;
    padding: 9px 19px 10px;
    text-align: center;
    svg {
        color: #8C939A;
        font-size: 20px; } }
.arrowRight {
    font-size: 25px;
    z-index: 1000;
    margin: auto;
    margin-top: 155px;
    color: rgba(0, 0, 0, 0.7215686275);
    margin-left: 10px;
    @include m {
        display: none; } }
.arrowLeft {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.7215686275); }
.exploreHead {
    font-size: 28px;
    color: black;
    font-weight: 300;
    font-family: 'RedHatDisplay';
    @include m {
        width: 100%;
        font-size: 24px;
        margin-bottom: 13px;
        text-align: center; } }
.description {
    @include redhat;
    color: #757171;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    @include m {
        text-align: center; } }
.filterBtn {
    display: none;
    @include m {
        background-color: #56b5eb;
        color: $white;
        bottom: 20px;
        z-index: 1;
        display: block;
        width: fit-content;
        margin: 0 auto;
        padding: 10px 20px;
        border-radius: 20px;
        font-size: 18px;
        position: fixed;
        left: 0;
        right: 0; }
    @include s;
 }    //    left: 30%
.selection {
    border-radius: 10px;
    border: 1px solid #e5e8eb;
    margin: 5%;
    color: #000000ed;
    font-size: 16px;
    font-weight: 300; }
.apply {
    font-size: 20px;
    text-align: center;
    color: #e5e8eb; }
.apply1 {
    font-size: 20px;
    text-align: center;
    color: #444546;
    cursor: pointer; }
.to {
    font-size: 18px;
    text-align: center;
    margin-top: 6px;
    font-weight: 500; }
.mobItem {
    display: none;
    @include m {
        display: flex;
        justify-content: center; } }
.mobItem2 {
    font-size: 14px;
    color: #000000b8;
    font-family: 'RedHatDisplay';
    padding: 20px;
    text-align: center;
    width: 170px;
    font-weight: bold;
    border: 1px solid #8e89e0;
    &:hover {
        box-shadow: 0px 0px 10px 3px #8e89e0;
        // border: 2px solid rgb(229, 232, 235)
        transition: scale(1.5);
        cursor: pointer; }
    &:first-child {
        border-top-left-radius: 10px; }
    &:nth-child(2) {
        border-top-right-radius: 10px; } }
.mobItem3 {
    font-size: 14px;
    color: #000000b8;
    font-family: 'RedHatDisplay';
    padding: 20px;
    text-align: center;
    width: 170px;
    font-weight: bold;
    border: 1px solid #8e89e0;
    &:hover {
        box-shadow: 0px 0px 10px 3px #8e89e0;
        // border: 2px solid rgb(229, 232, 235)
        transition: scale(1.5);
        cursor: pointer; }
    &:first-child {
        border-bottom-left-radius: 10px; }
    &:nth-child(2) {
        border-bottom-right-radius: 10px; } }

.buttonlarge {
    height: 3rem;
    width: 10rem;
    border: 1px solid #aca1a1;
    border-radius: 3rem;
    color: black;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 8%;
    @include m {
        height: 2rem; }
    &:hover {
        color: #ffffff;
        outline: none;
        background-color: #56B5EB;
        border: 1px solid black;
        box-shadow: 0 2px 8px 0 cyan; }
    &:focus {
        color: #ffffff;
        outline: none;
        background-color: #56B5EB;
        border: 1px solid black;
        box-shadow: 0 2px 8px 0 cyan; } }
.mobCreator {

    align-items: center;
    justify-content: center;
    span {
        @include m {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden; } } }
.menus {
    width: 54%;
    border: 1px solid rgb(229, 232, 235);
    border-radius: 10px;
    position: absolute;
    bottom: -3rem;
    right: 0;
    padding: 9px;
    cursor: pointer; }
.report {
    font-size: 32px;
    text-align: center;
    color: black;
    font-weight: 500;
    @include m {
        font-size: 24px;
        margin-bottom: 6%; } }
.report1 {
    font-size: 24px;
    color: black;
    font-weight: 500;
    margin-bottom: 2%;
    @include m {
        font-size: 24px;
        margin-bottom: 6%; } }

.report2 {
    background: #0000ffab;
    width: 80%;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    font-size: 20px;
    color: white;
    // margin-top: 5rem
    cursor: pointer;
    margin: 5rem auto 0;
    @include m {
        width: 72%;
        margin: 0 auto; } }

.report3 {
    font-size: 16px;
    margin-bottom: 6%;
    width: 80%;
    @include m {
        margin-bottom: 6%; } }
.report4 {
    background: #0000ff60;
    width: 80%;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    font-size: 20px;
    color: white;
    // margin-top: 5rem
    cursor: not-allowed;
    margin: 5rem auto 0;
    @include m {
        width: 72%;
        margin: 0 auto; } }
.assetLoader {
    margin: 100px auto; }
.emptyAssets {
    margin: 100px auto;
    text-align: center;
    width: 100%;
    font-size: 24px;
    font-weight: 900; }
.card {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
    &:hover {
        backdrop-filter: blur(3px); } }
.cardImage {
    border-radius: 10px;
    width: 100%;
    height: 180px; }
.cardTitle {
    @include redhat;
    font-size: 14px;
    font-weight: 500;
    color: black;
    margin-top: 3px; }
.assets {
    margin-top: 30px; }
.divider {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    border-bottom: none;
    border-color: #c2bebe70;
    margin: 5px 0; }
.price {
    display: flex;
    color: black;
    align-items: center;
    font-weight: 500;
    img {
        height: 22px; } }
.ethImage {
    // height: 15px
    font-size: 18px;
    color: grey; }
.usd {
    text-align: right;
    color: grey;
    margin-left: auto;
    font-size: 12px;
    font-weight: 600; }
.pagination {
    text-align: right;
    margin: 3% 0;
    @include m {
        text-align: center;
        margin: 5% 0; } }
.itemFlex {
    display: flex;
    align-items: baseline; }
.Item {
    display: flex;
    align-items: baseline;
 }    // margin-top: -11px
.docs {
    margin-top: 2px; }
.button {
    height: fit-content;
    width: fit-content;
    display: block;
    margin-left: auto;
    padding: 9px 30px; }
