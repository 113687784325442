@import "../../styles/helpers";

.dropdown {
    position: relative;
    z-index: 3;
    &.active {
        z-index: 10;
        .head {
            .arrow {
                svg {
                    transform: rotate(180deg); } } }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            position: inherit; } } }

.head {
    position: relative;
    height: 48px;
    padding: 0 48px 0 16px;
    border-radius: 12px;
    box-shadow: inset 0 0 0 1px #4f42c9;
    font-size: 14px;
    font-weight: 400;
    line-height: 48px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: box-shadow .2s;
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals3; }
    &:hover {
        box-shadow: inset 0 0 0 1px #f1535e; }
    @include m;
 }    //    padding: 0px 48px 0 55px;
.selection {
    overflow: hidden;
    @include redhat;
    font-size: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include t {
 }        // font-size: 15px;
    @include m {
 } }        // font-size: 15px;

.arrow {
    position: absolute;
    top: 50%;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px $neutrals6;
    transform: translateY(-50%);
    svg {
        fill: $neutrals4;
        transition: transform .2s; }
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals3;
        svg {
            fill: $neutrals8; } }
    // +m
    //     width: 24px;
 }    //     height: 24px;

.body {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    padding: 8px 0;
    border-radius: 12px;
    background: $neutrals8;
    box-shadow: inset 0 0 0 2px $neutrals6, 0 4px 12px rgba($neutrals2, .1);
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    @include dark {
        background: $neutrals1;
        box-shadow: inset 0 0 0 2px $neutrals3, 0 4px 12px rgba($neutrals1, .1); } }

.option {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    transition: #F2567A .2s;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:hover,
    &.selectioned {
        color: #4f42c9; } }
