@import "../../../styles/helpers";

.container {
    width: 100%;
    max-width: auto;
    margin: 0 auto;
    padding-left: 80px;
    padding-right: 80px;
    // padding: 0 80px
    margin-top: 5%;
    // +w
    //     width: 100%
    // +d
    //     width: 100%
    // +t
    //     padding: 0 40px
    // +m
    //     margin: auto
 }    //     padding: 10px 50px 0

.row {
    width: 100%;
    // display: flex
    // gap: 70px
    // margin-left: 250px
    // margin-top: 5%
    // margin: 10%

    // display: grid
    // grid-template-columns: 50%  50%  50%

    // grid-gap: 80px
    // margin-left: 62px
    // margin-bottom: 80px
    // margin-top: 50px

    // +m
    //     display: flex
    //     flex-wrap: wrap
    //     margin-left: -45px
    //     width: 200%
 }    //     gap: -200px

.feature {
    padding: 40px;
    // margin: 50px
    width: 50%;
    background-color: #E4ECEC;
    border-radius: 20px;
    @include redhat;
    color: black; }

.title {
    @include redhat;
    text-align: center;
    @include m {
        text-align: center;
        width: 275px;
        font-size: 15px;
        margin: 0 auto; }
    @include dark {
        color:  white; } }

.loader {
    margin: 32px auto 10px;
    @include dark {
        background: $neutrals1; } }

.line {
    display: block;
    border: solid 2px;
    color: #EE2151;
    width: 230px;
    height: 2px;
    background: #EE2151;
    border-left: none;
    border-left-color: currentcolor;
    border-right: none;
    border-right-color: currentcolor;
    margin: 20px auto;
    @include m {
        position: relative;
        border-radius: 10px;
        width: 100px;
        margin: 0 auto; } }


.top {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    @include m {
        display: block; }
    .dropdown {
        width: 180px;
        @include t {
            width: 260px; }
        @include m {
            width: 100%;
            margin-bottom: 12px; } } }

.nav {
    position: absolute;
    top: 10px;
    left: 50%;
    display: flex;
    justify-content: center;
    transform: translateX(-50%);
    @include t {
        display: none; } }

.link {
    margin: 0 6px;
    padding: 11px 5px;
    border-radius: 14px;
    background: none;
    font-size: 13px;
    line-height: 1.14286;
    font-weight: 700;
    color: $neutrals4;
    transition: all .2s;
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: #EE2151;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:nth-child(n+5) {
        @include d {
            display: none; } } }

.filter {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 24px;
    flex-shrink: 0;
    border-radius: 24px;
    background: $cyan;
    @include dm-sans;
    font-size: 16px;
    line-height: 48px;
    color: $neutrals8;
    transition: background .2s, box-shadow .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include m {
        width: 100%; }
    &.active {
        .toggle {
            svg {
                &:first-child {
                    display: none; }
                &:nth-child(2) {
                    display: inline-block; } } } } }

.toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    padding-right: 10px;
    background: none;
    font-size: 0;
    svg {
        fill: $neutrals8;
        &:first-child {
            width: 18px;
            height: 18px; }
        &:nth-child(2) {
            display: none;
            width: 10px;
            height: 10px; } } }
.filters {
    display: none;
    padding: 32px 0;
    border-top: 1px solid $neutrals6;
    @include dark {
        border-color: $neutrals3; }
    &.active {
        display: block; } }

.sorting {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;
    @include d {
        margin: -24px -8px 0; }
    @include m {
        display: block;
        margin: 0; } }

.cell {
    flex: 0 0 calc(25% - 32px);
    max-width: calc(25% - 32px);
    margin: 32px 16px 0;
    @include d {
        flex: 0 0 calc(25% - 16px);
        max-width: calc(25% - 16px);
        margin: 24px 8px 0; }
    @include t {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - 32px);
        margin: 32px 16px 0; }
    @include m {
        max-width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 20px; } } }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals5; }

.scale {
    display: flex;
    justify-content: space-between;
    margin-bottom: -3px;
    font-weight: 500; }

.list {
    @include m {
        margin: 0 -8px; } }

.slider {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    width: 100%;
    // margin-right: -60vh

    @include m {
        display: block;
        margin: 0; } }

.card {
    flex: 0 0 calc(25% - 32px);
    max-width: auto;
    margin-top: 20px;
    margin-left: 35px;
    margin-right: 35px;
    @include d {
        flex: 0 0 calc(33.333% - 32px);
        max-width: calc(33.333% - 32px); }
    @include t {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - 32px); }
    @include m {
        max-width: 100%;
        margin: 0; } }

.btns {
    margin-top: 32px;
    text-align: center;
    @include m {
        display: none; } }

.nopost {
    text-align: center;
    margin: 50px 0; }

.title {
    // font-size: 35px
    @include redhat;
    text-align: center;
    color:  black;
    margin-bottom: 10px;
    @include dark {
        color:  white; }
    @include m {
        margin: 16px  -14px; } }

.desc {
    margin: 20px auto;
    text-align: center;
    font-size: 16px;
    color: black;
    @include redhat;
    @include m {
        font-size: 13px;
        width: 100%;
        margin: 20px auto; } }
