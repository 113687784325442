@import "../../styles/helpers";

.title {
    @include redhat;
    text-align: start;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 0;
    @include m {
        font-size: 30px; } }
.text {
    margin-bottom: 40px;
    @include redhat;
    font-size: 20px;
    line-height: 1.5;
    color: black;
    font-weight: 100;
    text-align: center;
    margin-top: 4%;
    @include m {
        font-size: 15px; } }
.content {
    h3 {
        font-size: 24px;
        margin-left: 30px; }

    h5 {
        font-size: 18px;
        color: #4f42c9;
        margin-left: 50px;
        margin-bottom: 0.5em; }

    p {
        font-size: 16.5px;
        margin-top: -10px;
        margin-left: 50px;
        margin-bottom: 1em; } }

.container {
    margin-top: 2%;
    @include m {
        margin: 80px auto; } }
