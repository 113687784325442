@import "../../styles/helpers";
.container {
    margin-top: 2%;
    @include m {
        margin: 80px auto; } }
.title {
    @include redhat;
    text-align: start;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 0;
    @include m {
        font-size: 30px; } }
.field {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include m {
                margin-bottom: 20px; } } }
.sendbutton {
    width: fit-content;
    height: fit-content;
    padding: 9px 30px;
    font-size: 15px; }
.subject {
    width: 30%;
    height: 30%;
    padding: 49px 0px 28px 0px; }
.supporttext {
    @include redhat;
    font-size: 20px;
    margin-top: 49px;
    font-weight: 400;
    margin-bottom: 250px; }
