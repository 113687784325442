@import "../../../styles/helpers";

.section {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 610px;
    // margin-bottom: 15px;
    @include d {
        min-height: 576px; }
    @include m {
        display: block;
        min-height: 550px;
        margin-top: 40px;
        text-align: center; }
    @include dark {
        border-color: $neutrals3; } }

.wrap {
    position: relative;
    z-index: 3;
    width: 50%;
    @include m {
        width: 100%;
        margin-bottom: 40px; } }
.body {
    display: flex;
    @include m {
        display: block; } }
.stage {
    margin-bottom: 12px;
    @include redhat;
    font-size: 20px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    color: #777E90;
    @include m {
        font-size: 15px; } }

.title {
    font-size: 45px;
    width: 640px;
    line-height: 52px;
    @include redhat;
    margin-bottom: 20px;
    @include m;
    @include redhat;
    margin-bottom: 20px;
    width: 100%;
    font-size: 38px;
    @include dark {
        color: $neutrals7; } }

.text {
    margin-bottom: 40px;
    @include redhat;
    font-size: 16px;
    line-height: 1.5;
    color: black;
    @include m {
       font-size: 13px; } }

.btns {
    display: flex;
    @include m {
        display: block; } }


.button {
    @include redhat;
    @include m {
        width: 50%;
        height: 40px;
        margin-top: -20px; }
    &:not(:last-child) {
        margin-right: 16px;
        @include m {
            margin: 0 0 16px; } } }


.preview {
   width: 50%;
   @include m {
        width: 100%; } }
.image {
    height: 500px;
    display: flex;
    margin: 0 auto;
    @include m {
        height: auto;
        width: 100%; } }
.learnmore {
    font-size: 16px;
    font-weight: 500;
    color: #09B4BB;
    margin-bottom: 20vh; }
