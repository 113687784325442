@import "../../styles/helpers";

.nav {
    background: $neutrals8 !important;
    box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4);
    position: fixed !important;
    @include dark {
        background: $neutrals1 !important;
        box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4); } }


.toggle span {
    background-color: $neutrals4 !important;
    @include dark {
        background: $neutrals8 !important; } }

.container {
    width: 100%;
    overflow: hidden; }
