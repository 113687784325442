@import "../../../styles/helpers";

.user {
    padding: 32px 0px;
    border-radius: 16px;
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
    background: #cce1e1;
    border: 1px solid #4f42c9;

    @include redhat;
    text-align: center;
    @include dark {
        background: $neutrals2;
        border-color: $neutrals3; } }

.avatar {
    // width: 160px
    padding: 0 28px;
    height: 160px;
    margin: 0 auto 0px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include m {
      height: fit-content;
      width: fit-content; }
    img {
        max-width: 192px;
        min-width: 192px;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        @include t {
         object-fit: contain; } } }
.name {
    margin-bottom: 4px;
    padding: 0 28px;
    @include body-bold-1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%; }

.code {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    // margin-left: 5px
    padding: 0 28px;
    justify-content: center; }
.number {
    @include button-2;
    color: $neutrals2;
    // padding: 0 28px
    @include dark {
        color: $neutrals8; } }

.copy {
    margin-left: 8px;
    // padding: 0 28px
    svg {
        fill: $cyan;
        transition: fill .2s;
        margin-top: 5px; }
    &:hover {
        svg {
            fill: darken($cyan, 10); } } }

.info {
    margin-bottom: 20px;
    padding: 0 28px;
    @include caption-2;
    color: $neutrals4; }

.site {
    display: inline-flex;
    align-items: center;
    margin-bottom: 48px;
    @include button-2;
    color: $neutrals2;
    padding: 0 28px;
    @include dark {
        color: $neutrals8; }
    svg {
        margin-right: 8px;
        fill: $neutrals4; } }

.control {
    display: inline-block;
    position: relative; }

.box {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 8px);
    z-index: 2;
    width: 220px;
    transform: translateX(-50%);
    padding: 32px 16px;
    background: $neutrals8;
    border: 1px solid $neutrals6;
    box-shadow: 0px 32px 32px -8px rgba(31, 47, 70, 0.12);
    border-radius: 16px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: $neutrals2;
        border-color: $neutrals3; }
    &.active {
        visibility: visible;
        opacity: 1; } }

.stage {
    margin-bottom: 24px;
    font-weight: 500; }

.share {
    display: flex;
    justify-content: center; }

.direction {
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: $neutrals6;
        transition: all .2s;
        svg {
            transition: fill .2s; }
        &:hover {
            background: $cyan;
            svg {
                fill: $neutrals8; } }
        @include dark {
            background: $neutrals3;
            svg {
                fill: $neutrals8; }
            &:hover {
                background: $cyan;
                svg {
                    fill: $neutrals8; } } } }
    &:not(:last-child) {
        margin-right: 24px; } }

.btns {
    display: flex;
    justify-content: center;
    align-items: center; }

.button {
    @include redhat;
    margin-bottom: 20px;
    span {
        &:nth-child(2) {
            display: none; } }
    &.active {
        background: #EE2151;
        box-shadow: inset 0 0 0 2px #EE2151;
        @include dark {
            background: $cyan; }
        svg {
            fill: $neutrals8; }
        span {
            &:first-child {
                display: none; }
            &:nth-child(2) {
                display: inline; } } }
    &:not(:last-child) {
        margin-right: 8px; } }

.socials {
    display: flex;
    justify-content: center; }

.social {
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $cyan; } }
    &:not(:last-child) {
        margin-right: 24px; } }

.note {
    // margin-top: 48px
    // padding-top: 10px
    border-top: 1px solid $neutrals6;
    @include caption-2;
    color: $neutrals4;
    @include dark {
        border-color: $neutrals3; }
    @include m {
        margin-top: 10px; } }
.modalDiv {
    @include redhat;
    padding: 20px;
    h1 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 20px; }
    h3 {
        font-size: 14px;
        font-weight: 400;
        color: #706b6b;
        margin-bottom: 20px;
        text-align: left; } }
.kycButton {
    height: fit-content;
    display: block;
    padding: 10px 30px;
    font-weight: 100;
    font-weight: bold;
    font-size: 14px; }
.kycButton1 {
    height: fit-content;
    display: block;
    padding: 10px 30px;
    font-weight: 100;
    margin: 20px auto 0;
    font-weight: bold;
    font-size: 14px; }
.kycButton_1 {
    height: fit-content;
    display: block;
    padding: 10px 30px;
    font-weight: 100;
    margin: 20px auto 0;
    font-weight: bold;
    font-size: 14px;
    cursor: not-allowed; }
.citizenBtn {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 25px; }
.edit {
    font-size: 24px;
    cursor: pointer; }
.kyc {
    display: flex;
    justify-content: center;
    align-items: baseline;
    span {
        font-size: 15px;
        font-weight: bold;
        margin-right: 5px; } }
.verification {
    cursor: pointer;
    font-size: 13px;
    color: #0081ff;
    text-decoration: underline;
    margin-bottom: 0; }
.verification1 {
    cursor: not-allowed;
    font-size: 13px;
    color: green;
    margin-bottom: 0; }
.verification2 {
    cursor: not-allowed;
    font-size: 13px;
    color: #f1535e;
    margin-bottom: 0; }
.kycBtns {
    background: #f1535e;
    height: fit-content;
    display: block;
    padding: 10px 30px;
    font-weight: 100;
    font-weight: bold;
    font-size: 14px;
    color: white; }
.button1 {
    width: fit-content;
    padding: 9px 30px;
    font-size: 15px;
    height: fit-content;
    margin-left: 10px;
    cursor: pointer; }
.usercard {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background: white !important;
    height: 295px;
    padding: 20px;
    flex-direction: column;
    border: solid 1px #EE2151;
    border-radius: 12px;
    transition: 0.7s all ease-in-out; }

.page {
    // margin-top: 80px
    width: 600px;
    @include a {
     margin-top: 0px;
     width: auto; } }
.container1 {
    max-width: 896px;
    margin-top: 0px;
    padding: 0; }

.top {
    margin-bottom: 34px;
    @include m {
        // margin-bottom: 32px
        padding-bottom: 16px;
        // border-bottom: 1px solid $neutrals6
        @include dark {
            border-color: $neutrals3; } } }

.title {
    margin-bottom: 16px;
    text-align: center;
    @include m {
     margin-bottom: 0px;
     text-align: left; } }

.info {
    color: $neutrals4;
    strong {
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; } } }

.row {
    // display: flex
    margin: 0 -16px;
    @include d {
        display: block;
        margin: 0; } }

.col {
    // flex: 0 0 calc(50% - 32px)
    // width: calc(50% - 32px)
    margin: 0 16px;
    @include d {
        width: 100%;
        margin: 0; }
    &:not(:last-child) {
        @include d {
            margin-bottom: 64px; }
        @include m {
            margin-bottom: 32px;
            padding-bottom: 32px;
            // border-bottom: 1px solid $neutrals6
            @include dark {
                border-color: $neutrals3; } } } }

.user1 {
    display: flex;
    @include d {
        max-width: 416px; } }

.avatar1 {
    flex-shrink: 0;
    width: 128px;
    height: 128px;
    margin-right: 32px;
    @include m {
        width: 64px;
        height: 64px;
        margin-right: 16px; }
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.details {
    flex-grow: 1; }

.stage {
    margin-bottom: 8px;
    @include body-bold-2; }

.text {
    margin-bottom: 16px;
    @include caption-2;
    color: $neutrals4; }

.file {
    display: inline-block;
    position: relative;
    overflow: hidden; }

.load {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 140px;
    opacity: 0; }
.load1 {
    position: absolute;
    font-size: 10px;
    opacity: 0;
    height: 40px;
    cursor: pointer; }

.list {
    margin: 20px 0;
    @include d {
        // display: flex
 }        // margin: 0 -20px 64px
    @include m {
        // display: block
 } }        // margin: 0 0 32px

.item {
    @include d {
        flex: 0 0 calc(50% - 40px);
        width: calc(50% - 40px);
        margin: 0 20px; }
    @include m {
        width: 100%;
        margin: 0; }
    & > .button {
        margin-top: 32px; }
    &:not(:last-child) {
        margin-bottom: 40px;
        @include d {
            margin-bottom: 0; }
        @include m {
            margin-bottom: 32px; } } }

.category {
    margin-bottom: 32px;
    @include body-bold-2; }

.fieldset {
    & > .field {
        border: 1px solid black;
        &:not(:last-child) {
            margin-bottom: 32px; } } }

.box {
    position: relative;
    .button {
        cur {}
        position: absolute;
        right: 12px;
        bottom: 8px;
        height: 32px;
        padding: 0 10px; } }

.note {
    color: $neutrals4; }

.btns {
    display: flex;
    justify-content: center;
    // margin-top: 40px
    // padding-top: 40px
    // border-top: 1px solid $neutrals6
    @include m {
        display: block;
        text-align: center; }
    @include dark {
        border-color: $neutrals3; }
    .button {
        @include m {
            width: auto;
            margin-left: -111px; }
        @include a {
         margin-left: -13px; }
        &:not(:last-child) {
            margin-right: 32px;
            @include m {
                margin: 0 0 32px; } } } }

.clear {
    display: inline-flex;
    align-items: center;
    @include button-1;
    color: $neutrals4;
    transition: color .2s;
    svg {
        margin-right: 8px;
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        color: $cyan;
        svg {
            fill: $cyan; } } }
.editname {
    border: 1px solid #655c5ca6;
    height: 36px;
    width: 202px;
    border-radius: 8px;
    margin-left: 12px;
    padding: 5px 10px; }
.editname1 {
    border: 1px solid #655c5ca6;
    height: 36px;
    width: 202px;
    border-radius: 8px;
    margin-left: 12px;
    padding: 5px 10px; }
.edit_1 {
    display: flex;
    align-items: center; }
.lastname {
    @include redhat;
    margin-left: 31px;
    font-size: 15px;
    font-weight: 400 {
     color: #000000; } }
.editname2 {
    border: 1px solid #655c5ca6;
    height: 36px;
    width: fit-content;
    border-radius: 8px;
    margin-left: 46px;
    padding: 5px 10px; }
.name_1 {
    display: flex;
    margin-top: 16px;
    align-items: center; }
.heading {
    @include redhat;
    font-size: 15px;
    font-weight: 400;
    color: #000000; }
.headings {
    @include redhat;
    font-size: 15px;
    font-weight: 400;
    color: #000000;
    margin-left: 20px; }
.editname_2 {
    border: 1px solid #655c5ca6;
    height: 36px;
    width: fit-content;
    border-radius: 8px;
    margin-left: 13px;
    padding: 5px 10px; }
.mobno {
    display: flex;
    margin-top: 16px; }
.nameFlex,.emailFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include m {
     display: flex;
     width: 75%; }
    @include a {
     flex-direction: column;
     width: 100%; } }
.nameFlex,.mobDiv {
    display: none !important; }

.firstNameDiv,.lastNameDiv,.emailDiv,.mobDiv {
    display: flex;
    width: 50%;
    align-items: baseline;
    margin: 10px 0;
    h5 {
        @include redhat;
        font-size: 15px;
        font-weight: 400;
        color: #000000;
        width: 35%;
        margin-left: 10px; }
    @include a {
      flex-direction: column;
      width: 100%; } }
.firstNameInput,.lastNameInput,.emailInput,.mobInput {
    border: 1px solid #655c5ca6;
    width: 65%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 8px;
    margin-left: 13px;
    padding: 2px 10px;
    height: 28px; }
.uploadimage {
    display: flex;
    margin-bottom: 30px;
    padding: 20px;
    border: 2px dashed #E6E8EC; }
.upload1 {
    display: flex;
    justify-content: space-around;
    margin: 0 10px 20px; }

.upload2 {
    p {
        margin: 10px 0 0 !important;
        text-align: center; } }
.uploadText {
    margin: 0 10px;
    text-align: center;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    h5 {
        color: white;
        margin: 0;
        font-size: 15px; }
    @include t {
      font-size: 12px; }
    @include s {
     font-size: 8px; } }
.imageurl {
    border: 1px solid black;
    width:  100%;
    height: 50px;
    margin-top: 41px;
    text-align: center; }
.imageurl_1 {
    border: 1px solid black;
    width:  100%;
    height: 50px;
    margin-top: 41px;
    text-align: center; }
.field {
    margin-bottom: 25px; }
.field1 {
    width: 50%; }
.frontimg {
    height: 100%;
    width: 97%;
    padding: 5px; }
.imageUpload {
    border: 2px dashed #E6E8EC;
    width:  529px;
    height: fit-content;
    margin-left: 20px; }
.editimg {
    display: flex;
    justify-content: center;
    svg {
        font-size: 20px;
        margin: 5px 17px; } }
.load2 {
    position: absolute;
    font-size: 4px;
    opacity: 0;
    left: 174px; }
.load3 {
    position: absolute;
    font-size: 4px;
    opacity: 0;
    right: 294px; }
.panimageUpload {
    border: 2px dashed #E6E8EC;
    width:  100%;
    height: fit-content;
    margin-bottom: 10px; }
.Upload {
    // border: 2px dashed #E6E8EC
    // width:  100%
    // height: 80px
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        margin-left: 20px;
        margin-bottom: 0; } }
.textpan {
    height: fit-content;
    padding: 14px 24px;
    h5 {
        color: white;
        margin-bottom: 0;
        font-size: 15px; } }
.frontimage {
    height: 100%;
    width: 80%;
    padding: 5px; }
.editimage {
    display: flex;
    justify-content: center;
    svg {
        font-size: 20px;
        margin: 5px 17px; } }
.loading {
    position: absolute;
    font-size: 3px;
    opacity: 0;
    left: 259px; }

.loader {
    margin: 30px; }
.panUpload {
    display: flex;
    align-items: baseline; }
.notes {
    font-size: 14px;
    color: #706b6b; }
.aadharInput {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    h2 {
        font-size: 15px;
        margin-right: 20px;
        margin-bottom: 0; } }
.errorMsg {
    margin: 10px 0;
    color: red; }
.errorMsg1 {
    margin: 0px 0 10px;
    color: red; }
.nameLoad {
    margin: 18px auto; }
.popover {
    // position: absolute
    // box-shadow: 0px 0px 20px #b2b2b2
    background: $white;
    margin-top: 30px;
    // top: 70px
    // right: 10px
    // z-index: 100
    // padding: 10px 20px
    font-size: 18px;
    @include redhat;
    font-weight: 500; }
.registerInput {
    margin-bottom: 20px; }
.loginDiv {
    width: 400px;
    @include m {
        width: fit-content; } }
.button1 {
    background: #eeeeee;
    color: black;
    cursor: not-allowed;
    width: fit-content;
    height: fit-content;
    padding: 9px 30px;
    font-size: 15px;
    margin-left: 0;
    &:hover {
         background: #eeeeee;
         color: black;
         cursor: not-allowed;
         box-shadow: 0; } }
.button {
    width: fit-content;
    height: fit-content;
    padding: 9px 30px;
    font-size: 15px; }
.heading {
     font-size: 22px;
     font-weight: bold;
     margin-bottom: 0; }
.heading1 {
     font-size: 22px;
     font-weight: bold;
     margin-bottom: 23px; }
.okButton {
    width: fit-content;
    height: fit-content;
    padding: 9px 30px;
    font-size: 15px;
    margin: 20px auto 0;
    display: block; }
