@import "../../../styles/helpers";

.user {
    position: relative;
    @include m {
        position: static; } }

.head {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px 16px 4px 4px;
    border-radius: 20px;
    box-shadow: inset 0 0 0 2px $neutrals6;
    @include button-2;
    cursor: pointer;
    transition: box-shadow .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals3; }
    @include m {
        padding: 0;
        border-radius: 0;
        box-shadow: none; }
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals3;
        @include m {
            box-shadow: none; } }
    &:hover {
        box-shadow: inset 0 0 0 2px $cyan;
        @include m {
            box-shadow: none; } } }

.avatar {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%; } }
.avatar1 {
    flex-shrink: 0;
    width: 31px;
    height: 31px;
    margin-right: 8px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%; }
    @include m {
        display: block !important; } }

.walletname {
    margin-left: 12px;
    @include m {
        display: none; } }

.currency {
    color: $green; }
.body1 {
    position: absolute;
    top: calc(100% + 20px);
    left: 51%;
    width: 256px;
    margin-left: -128px;
    padding: 32px 16px 20px;
    box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4);
    border-radius: 12px;
    background: $neutrals8;
    opacity: 0;
    transform: scale(.9);
    transform-origin: 50% 50px;
    will-change: transform;
    animation: showUser .4s forwards;
    @include redhat;
    z-index: 4;
    @include t {
        left: 0; }
    @include m {
        left: 0;
        top: 76px;
        width: fit-content;
        align-content: center;
        margin: 3px;
        padding: 10px 20px 10px 10px; }
    &:before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 32px;
        height: 13px;
        transform: translateX(-50%);
        background: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A") no-repeat 100% 50% / 100% auto;
        @include m {
            display: none; } }

    @include dark {
        background: $neutrals2;
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%2323262F'/%3E%3C/svg%3E%0A"); } } }

@keyframes showUser {
    0% {
        opacity: 0;
        transform: scale(.9); }
    100% {
        opacity: 1;
        transform: scale(1); } }
.body {
    position: absolute;
    top: calc(100% + 20px);
    left: 51%;
    width: 256px;
    margin-left: -128px;
    padding: 32px 16px 20px;
    box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4);
    border-radius: 12px;
    background: $neutrals8;
    opacity: 0;
    transform: scale(.9);
    transform-origin: 50% 50px;
    will-change: transform;
    animation: showUser .4s forwards;
    @include redhat;
    z-index: 4;
    @include t {
        left: auto;
        right: 0; }
    @include m {
        right: inherit;
        top: 76px;
        width: fit-content;
        align-content: center;
        margin: 3px;
        left: inherit; }
    &:before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 32px;
        height: 13px;
        transform: translateX(-50%);
        background: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A") no-repeat 100% 50% / 100% auto;
        @include m {
            display: none; } }

    @include dark {
        background: $neutrals2;
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%2323262F'/%3E%3C/svg%3E%0A"); } } }

@keyframes showUser {
    0% {
        opacity: 0;
        transform: scale(.9); }
    100% {
        opacity: 1;
        transform: scale(1); } }

.name {
    @include body-bold-1; }

// .code
//     display: flex
//     align-items: center
//     margin-bottom: 8px
//     margin-left: 13%;

.number {
    font-weight: 600;
    color: black;
    @include redhat;
 }    // font-family: Roboto;

// .wrap
//     margin-bottom: 7px
//     padding: 8px
//     border-radius: 16px

//     // box-shadow: 24px 24px 24px 24px rgba(15, 15, 15, 0.2)

// .line
//     display: flex
//     align-items: center
//     margin-bottom: 12px;
//     margin-left: -2%;

// .preview
//     flex-shrink: 0
//     width: 40px
//     height: 40px
//     margin-right: 17px
//     img
//         width: 55%;
//         height: 55%;
//         border-radius: 50%;
//         margin-left: 60%;
//         margin-top: 26%;

// .info
//     font-size: 15px;
//     color: black
//     margin-left: 3%;
//     font-weight: 600;
//     +redhat
//  // font-family: Roboto;
.price {
    @include redhat;
    color: black;
    font-weight: 800;

 }    // font-family: Roboto;

.button {
    width: fit-content;
    padding: 9px 30px;
    font-size: 15px;
    height: fit-content;
    margin-left: 10px;
    cursor: pointer;
    @include m {
        padding: 10px 15px;
        margin-left: 15px; }
    @include m {
 } }        // display: none
.buttonFlex {
    padding: 0px 26px;
    font-size: 15px;
    font-family: 'RedHatDisplay';
    height: -moz-fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    @include m {
        padding: 10px 15px; } }
.avatar {
    display: none;
    @include m {
 } }        // display: block

// .item
//     display: flex
//     align-items: center
//     padding: 17px 0 12px
//     +button-2
//     color: $neutrals4
//     &:not(:last-child)
//         border-bottom: 1px solid $neutrals6
//         +dark
//             border-color: $neutrals3
//     &[href]
//         &:hover
//             color: $cyan
//             svg
//                 fill: $cyan

// .icon
//     flex-shrink: 0
//     width: 30px
//     margin-right: 8px
//     svg
//         fill: black
//         transition: fill .2s

.wallet {
    width: 280px;
    cursor: pointer;
    border: 2px solid $neutrals6;
    border-radius: 12px;
    height: 55px;
    @include dark {
        border-color: $neutrals3; }
    @include t {
        width: 260px; }
    @include m {
        width: 100%;
        margin-bottom: 12px; } }

.modal {
    padding: 0 42px;
    @include m {
        padding: 0 5px; } }

.metamask {
    height: 40px;
    width: 40px;
    margin: 5px 25px; }

.magicwallet {
    height: 50px;
    width: 90px;
    margin-top: 10px;
    // margin-top: 10px
 }    // margin: 5px 25px
.walletconnect {
    width: 35px;
    margin: 5px 27px; }
.flexBtn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    width: 280px;
    cursor: pointer;
    border: 2px solid $neutrals6;
    border-radius: 12px;
    height: 55px;
    @include dark {
        border-color: $neutrals3; }
    @include t {
        width: 260px; }
    @include m {
        width: 100%;
        margin-bottom: 12px; } }

.iconimg {
    float: right;
    margin-top: 14px; }

.iconimg1 {
    float: right;
    margin-top: -25px; }

.widthIcon {
    height: 100%;
    width: 33%; }

.center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }

.left {
    height: 100%;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: left; }

.left1 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -41px;
    margin-left: 85px;
    @include m {
        margin-left: 101px; } }
.btnContent {
    width: 60%; }

// .item
//     display: flex
//     margin-left: 12%;
//     color: black;
//     cursor: pointer;

.register {
    margin-top: 32px;
    margin-bottom: 16px; }

.field {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include m {
            margin-bottom: 20px; } } }

.cursor {
    cursor: pointer; }
// .Accounticondesign
//     +redhat
//     margin-left: 26%;
//     margin-top: -15%;
//     color: black
//     cursor: pointer
//     &:hover
//         color: #3CC5C5
//         +redhat
//     // font-family: Roboto;
.Accountimagedesign {
    width: 30px;
    height: 30px;
    align-items: center;
    margin-right: 20px;
    // display: flex
 }    // position: relative

.line {
    display: flex;
    @include redhat;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
    margin-left: 25px;
    margin-top: 10px;
    @include m {
        margin-left: 6px; } }

.userimg {
    display: flex;
    @include redhat;
    font-weight: bold;
    flex-direction: row;
    margin-left: 18px;
    margin-top: 1px;
    color: #4f42c9;
    @include m {
        margin-left: 6px; } }
.profileName {
    width: 76%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
.line12 {
    display: flex;
    @include redhat;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
    color: black;
    margin-left: 25px;
    margin-top: 10px;
    @include m {
        margin-left: 6px; } }

.line_2 {
    &:hover {
        color: #F2567A; } }

.details {
    align-content: center; }

.code {
    display: flex;
    // margin-left: 25px
    justify-content: space-evenly;
    @include m {
        margin-left: 10px; } }

.item {
    display: flex;
    align-items: center;
    margin-left: 29px;
    margin-top: 10px;
    @include m {
        margin-left: 7px;
        align-items: self-start; } }

.icon {
    flex-shrink: 3;
    width: 30px;
    margin-right: 20px;
    margin-top: 5px;
    svg {
        fill: black;
        transition: fill .2s; }
    @include m {
        margin-top: 0; } }

.line123 {
    border-top: solid 1px;
    color: #c1c1c1;
    width: auto;
    margin-top: 10px; }

.copy {
    font-family: roboto;
    font-weight: 400px;
    // margin-left: 18px
    svg {
        fill: #4e47c1;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: darken(#4e47c1, 10); } } }
// .title
//     display: flex
//     flex-direction: row
//     align-items: center
//     margin-left: 25px
//     margin-top: 10px
//     gap: 10px

.loginFlex {
    display: flex;
    @include m {
        align-items: center; } }
.connectcontainer {
    width: 400px;
    @include m {
        width: fit-content; } }
.signin {
    border-radius: 20px;
    background-color: rgba(209,213,219,var(--tw-bg-opacity));

    --tw-bg-opacity: 1;
    padding: 20px;
    svg {
        font-size: 20px; }
    @include m {
        padding: 10px; } }

.register {
    border-radius: 20px;
    background-color: rgba(209,213,219,var(--tw-bg-opacity));
    --tw-bg-opacity: 1;
    padding: 20px;
    svg {
        font-size: 20px; }
    @include m {
      margin-left: 23px;
      padding: 10px; } }
.connectflex {
    display: flex;
    justify-content: space-around;
    align-items: baseline; }
.header {
    text-align: center;
    @include redhat; }

.headname {
    font-size: 22px;
    font-weight: bold; }
.name {
    @include redhat;
    font-size: 15px;
    font-weight: 400;
    @include m {
       font-size: 12px; } }
.button1 {
    width: fit-content;
    padding: 9px 30px;
    font-size: 15px;
    height: fit-content;
    margin-left: 10px;
    cursor: pointer;
    @include m {
        padding: 10px 15px;
        margin-left: 0;
        margin-right: 20px; } }
.emailname {
    border: 1px solid; }

.section {
    padding: 35px 0;
    @include x {
        padding-top: 80px; }
    @include m {
        padding-top: 64px; } }

.container {
    padding: 0 35px;
    align-items: flex-start;
    @include t {
        display: block; } }

.preview {
    flex-shrink: 0;
    width: 352px;
    @include d {
        width: 304px; }
    @include t {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 48px;
        background: rgba($neutrals1,.9);
        overflow: auto;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        transition: all .4s;
        @include dark {
            background: rgba($neutrals2,.9); }
        &.active {
            visibility: visible;
            opacity: 1; } }
    @include m {
        padding: 32px 16px; } }

.image {
    height: 70px;
    width: 70px;
    display: block;
    margin-left: auto;
    margin-right: auto; }

.head {
    display: flex;
    margin-bottom: 40px;
    @include d {
        flex-direction: column-reverse;
        align-items: flex-start; }
    @include m {
        margin-bottom: 32px; }
    .button {
        flex-shrink: 0;
        margin-left: 24px;
        @include d {
            margin: 0 0 16px; } } }

.title {
    margin-right: auto; }

.list {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $neutrals6;
    @include m {
        margin-bottom: 32px;
        padding-bottom: 32px; }
    @include dark {
        border-color: $neutrals3; } }

.item {
    &:not(:last-child) {
        margin-bottom: 20px;
        @include m {
            margin-bottom: 32px; } } }

.note {
    margin-top: 4px;
    @include caption-2;
    color: $neutrals4; }

.file {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 182px;
    margin-top: 16px;
    border-radius: 16px;
    overflow: hidden;
    background: $neutrals7;
    @include dark {
        background: $neutrals2; } }

.load {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 400px;
    opacity: 0; }

.format {
    @include caption-2;
    color: $neutrals4; }

.category {
    @include body-bold-2;
    text-align: center; }

.fieldset {
    margin-top: 18px;
    .field {
        &:not(:last-child) {
            margin-bottom: 18px;
            @include m {
                margin-bottom: 20px; } }
        .basic-multi-select {
            border-radius: 12px !important;
            background-color: red !important; } } }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals5; }

.row {
    display: flex;
    margin: 0 -10px;
    @include m {
        display: block;
        margin: 0; } }

.col {
    flex: 0 0 calc(33.333% - 20px);
    width: calc(33.333% - 20px);
    margin: 0 10px;
    @include m {
        width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 20px; } } }

.options {
    margin-bottom: 40px;
    @include m {
        margin-bottom: 32px; } }

.option {
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
        margin-bottom: 32px; } }

.box {
    flex-grow: 1; }

.switch {
    flex-shrink: 0;
    margin-left: 24px; }

.text {
    // margin-top: 4px
    @include caption-2;
    color: $neutrals4;
    text-align: center;
    font-size: 14px;
    @include m {
        margin-top: 0; } }

.foot {
    display: flex;
    align-items: center;
    @include m {
        display: block;
        text-align: center; }
    .button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 24px;
            @include m {
                margin: 0 0 12px; } } } }

.saving {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    @include button-1; }

.loader {
    margin-right: 24px;
    margin-top: 14px; }

.error {
    font-size: 12px;
    color: red; }

.button {
    width: 100%; }
.dropdown {
    width: 641px;
    .react-select__control {
        border-radius: 12px !important;
        background-color: red !important; } }

.link {
    color: blue; }
.forgotlink {
    color: blue;
    text-align: right;
    font-size: 12px;
    margin-bottom: -18px; }
.linkhome {
    color: blue;
    text-align: center;
    font-size: 12px;
    display: flex;
    margin: 10px auto;
    width: max-content; }
.alert {
    color: red;
    font-size: 12px;
    margin: -10px 0 10px 0px; }
.header1 {
    text-align: center;
    @include redhat;
    font-size: 22px;
    font-weight: 500; }
.cancelbutton {
    width: fit-content;
    height: fit-content;
    padding: 9px 30px;
    font-size: 15px; }
.buttons {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    // margin-top: 15%
    @include m {
 } }        // margin-top: 32%
.registerInput {
    margin-bottom: 20px; }
.registerInput1 {
    margin-bottom: 20px;
    display: none; }
.loginDiv {
    width: 400px;
    @include m {
        width: fit-content; } }
.registerDiv {
    width: 500px;
    @include redhat;
    padding: 10px;
    h1 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: bold;
        @include m {
            font-size: 18px;
            text-align: start; } }
    @include m {
        width: fit-content; } }

.registerBtn {
    display: flex;
    justify-content: space-evenly;
    // margin-top: 12%
    @include m {
 } }        // margin-top: 26%
.registerButton {
    padding: 9px 30px;
    font-size: 15px;
    width: fit-content;
    height: fit-content; }
.passwordShow {
    margin-bottom: -25px;
    svg {
        position: relative;
        bottom: 54px;
        left: 447px;
        font-size: 18px;
        @include m {
            left: 204px; }
        @include s {
            left: 190px; } } }
.passwordShow1 {
    margin-bottom: -25px;
    svg {
        position: relative;
        bottom: 53px;
        left: 447px;
        font-size: 18px;
        @include m {
            left: 204px; }
        @include s {
            left: 190px; } } }

.registerTxt {
    // position: absolute
    // bottom: 12%
    // text-align: center
    margin-bottom: 20px;
    @include m {
        font-size: 12px; } }
.loginTxt {
    // position: absolute
    // bottom: 20%
    margin-bottom: 20px;
    @include m {
        font-size: 12px;
        line-height: 1.2;
        bottom: 18%; } }
.loginTxt1 {
    // position: absolute
    // bottom: 20%
    margin-bottom: 10px;
    @include m {
        font-size: 12px;
        line-height: 1.2;
        bottom: 18%; } }
.passwordShow2 {
    margin-bottom: -30px;
    svg {
        position: relative;
        bottom: 53px;
        left: 370px;
        font-size: 18px;
        @include m {
            left: 230px; }
        @include s {
            left: 190px; } } }
.profileName {
    @include m {
        margin-left: 11px; } }
.loaders {
    margin-left: 20px; }
.css-1xcc5ro {
    margin: -5px 5px -5px 10px; }
.profileNameSlice {
    width: fit-content;
    border: 1px solid #4F42C9;
    border-radius: 50%;
    padding: 5px 12px;
    margin-right: 15px;
    background: #4F42C9;
    color: white;
    &:hover {
 } }        // color: black !important
.buyLine {
    display: flex;
    @include redhat;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
    margin-left: 25px;
    margin-top: 10px;
    cursor: pointer;
    svg {
        margin-right: 20px;
        font-size: 30px; }
    h5 {
        font-weight: 800;
        font-size: 14px;
        color: black;
        &:hover {
            color: #F2567A; } }
    @include m {
        margin-left: 6px; } }
.popover {
    position: absolute;
    box-shadow: 0px 0px 20px #b2b2b2;
    background: $white;
    top: 70px;
    right: 10px;
    z-index: 100;
    padding: 10px 20px;
    @include redhat;
    font-weight: 500; }
.inputlabel {
    @include redhat;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    color: black;
    &:after {
        content: '*';
        color: red;
        font-size: 19px; } }
.inputlabel1 {
    @include redhat;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    color: black;
    display: none;
    &:after {
        content: '*';
        color: red;
        font-size: 19px; } }
.astrisk {
     color: red;
     font-size: 19px; }
.impo {
    font-weight: bold; }
