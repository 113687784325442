@import "../../styles/helpers";

.page {
    height: 100%;
    overflow: hidden;
    background-color: white;
    // background-image: url('../../components/img/grpbg1.png')
    // background-repeat: no-repeat
    // background-size: cover


    @include dark {
        overflow: hidden;
        background-color: white;
        // background-image: url('../../components/img/darkbg.png')
        // background-repeat: no-repeat
 } }        // background-size: 100% 100%

.inner {
    margin-top: 7%;
    @include redhat; }
