@import "../../../styles/helpers";

.title {
    color: #3cc5c5;
    margin-bottom: 64px;
    @include roboto;

    @include m {
        margin-bottom: 32px; } }

.loader {
    margin: 32px auto 10px;
    @include dark {
        background: $neutrals1; } }

.top {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    @include m {
        display: block; }

    .dropdown {
        width: 180px;
        @include t {
            width: 260px; }
        @include m {
            width: 100%;
            margin-bottom: 12px;


            // justify-content: space-between

 } } }            // margin-bottom: 12px

.nav {
    position: absolute;
    top: 10px;
    left: 50%;
    display: flex;
    justify-content: center;
    transform: translateX(-50%);
    @include d;
    //    font-size: 10px
    @include t {
        display: none; }
    @include m {
 } }        // display: none
.title12345 {
    @include roboto; }
.link {
    margin: 0 6px;
    margin: -1px 11px;
    padding: 5px 5px;
    // border: 1px solid black;
    border-radius: 14px;
    background: none;
    font-size: 14px;
    // font-family: Roboto;
    line-height: 1.14286;
    font-weight: 400;
    color: black;
    @include roboto;
    transition: all .2s;
    @include d {
        padding: 4px 2px;
        font-size: 12px; }
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: #3CC5C5;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:nth-child(n+5) {
        @include d {
 } } }            // display: none

.filter {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 24px;
    flex-shrink: 0;
    border-radius: 24px;
    background: #31b5b6;
    @include dm-sans;
    font-size: 16px;
    line-height: 48px;
    color: $neutrals8;
    transition: background .2s, box-shadow .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include m {
        width: 100%; }

    &.active {
        .toggle {
            svg {
                &:first-child {
                    display: none; }
                &:nth-child(2) {
                    display: inline-block; } } } } }

.toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    padding-right: 10px;
    background: none;
    font-size: 0;
    svg {
        fill: $neutrals8;
        &:first-child {
            width: 18px;
            height: 18px; }
        &:nth-child(2) {
            display: none;
            width: 10px;
            height: 10px; } } }
.filters {
    display: none;
    padding: 32px 0;
    border-top: 2px solid #928F8F;
    @include dark {
        border-color: $neutrals3; }
    &.active {
        display: block; } }

.sorting {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;
    @include d {
        margin: -24px -8px 0; }
    @include t {
        margin: -24px -16px 0; }
    @include m {
        display: block;
        margin: 0; } }

.cell {
    flex: 0 0 calc(25% - 32px);
    max-width: calc(25% - 32px);
    margin: 32px 16px 0;
    @include d {
        flex: 0 0 calc(25% - 16px);
        max-width: calc(25% - 16px);
        margin: 24px 8px 0; }
    @include t {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - 32px);
        margin: 32px 16px 0; }
    @include m {
        max-width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 20px; } } }

.label {
    margin-bottom: 20px;
    @include hairline-2;
    font-size: 16px;
    @include roboto;
    color: black; }

.scale {
    display: flex;
    justify-content: space-between;
    margin-bottom: -3px;
    font-weight: 500; }

.list {
    @include m {
        margin: 0 -8px; }
    @include a {
        margin: 0px -20px; } }

.slider {
    display: flex;
    flex-wrap: wrap;
    margin: -11px -16px 0;
    @include m {
        margin: -11px -16px 0; }
    @include a {
        display: block;
        margin: 0; } }
// .card
//     flex-grow: 0;
//     flex-shrink: 0;
//     flex: 0 0 calc(33.33% - 32px)
//     max-width: calc(33.33% - 32px)
//     margin: 32px 15px 0;
//     +d
//         flex: 0 0 calc(50% - 32px)
//         max-width: calc(50% - 32px)
//         margin: 32px 16px 0
//     +t
//         flex: 0 0 calc(50% - 32px)
//         max-width: calc(50% - 32px)
//     +m
//         flex: 0 0 calc(50% - 32px)
//         max-width: calc(50% - 32px)
//     +s
//         flex: 0 0 calc(100% - 32px)
//         max-width: calc(100% - 32px)

.card {
    flex: 0 0 calc(25% - 32px);
    max-width: calc(65% - 32px);
    margin: 15px;
    @include d {
        flex: 0 0 calc(33.333% - 32px);
        max-width: calc(33.333% - 32px); }
    @include t {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - 32px); }
    @include m {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - 32px); }
    @include a {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(90% - -132px); } }

.btns {
    margin-top: 32px;
    text-align: center;
    @include m {
        display: none; } }

.nopost {
    text-align: center;
    margin: 50px 0; }
