@import "../../styles/helpers";

.card {
    display: flex;
    flex-direction: column;
    // padding: 10px
    border: 2px solid #D3CFFA;
    background-color: #FAFAFA;
    border-radius: 10px;
    margin-bottom: 5%;
    background-clip: padding-box;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(25% - 32px);
    max-width: calc(65% - 32px);
    margin: 32px 15px 0;
    @include d {
        flex-basis: calc(33.33% - 32px);
        max-width: calc(33.33% - 32px); }
    @include m {
        flex-basis: calc(50% - 32px);
        max-width: calc(50% - 32px); }
    @include a {
        flex-basis: calc(100% - 32px);
        max-width: calc(100% - 32px); }
    &:hover {
        border: 2px double transparent;
        border-radius: 15px;
        background-image: linear-gradient(#fff,#fff),radial-gradient(circle at top, #F81B66, #4F42C9) !important;
        background-origin: border-box !important;
        background-clip: content-box,border-box !important;
        padding: 0px;
        background-color: #E3E0FA;
        box-shadow: 0px 0px 20px 2px #e3e0fa;
        // &::after
        //     position: absolute;
        //     top: -2px;
        //     bottom: -2px;
        //     left: -2px;
        //     right: -2px;
        //     background: linear-gradient(#e21dfc,#777e90);
        //     content: '';
        //     z-index: -1;
        //     border-radius: 16px;
        .control {
            // visibility: visible
            opacity: 1; } } }

.cardPreview {
    margin: 10px; }

.preview {
    position: relative;
    overflow: hidden;
    // height: 100%
    // max-height: 350px
    min-height: 247px;
    max-height: 247px;
    @include m {
        max-height: fit-content; }
    & > img,video {
        width: 100%;
        // height: 80%
        transition: 1s all ease-in-out;
        border-radius: 10px;
        min-height: 210px;
        max-height: 210px; } }


.preview:hover {
    // border: 3px solid #EE9200
    // border-radius: 15px
    // box-shadow: 0 0 10px #EE9200
    // animation: glow 1s ease-in-out infinite alternate
    // background-color: #757171
    & > img {
 }        // transform: scale(1.3)
    .title {
        color: #EE9200;
 } }        // transform: scale(1.3)
.control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $neutrals3;
    color: black;
    visibility: hidden;
    border-radius: 16px;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: $neutrals8; } }

.category {
    position: absolute;
    top: 11px;
    left: 8px; }

.tokenPrice {
    font-weight: 100;
    font-size: 11px;
    @include redhat;
    color: grey;
    margin-bottom: 0; }

.favorite {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background: $neutrals8;
    box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2);
    border-radius: 50%;
    svg {
        width: 20px;
        height: 20px;
        fill: $neutrals4;
        transition: fill .2s;
        transition: opacity .2s; }
    &:before {
        content: "";
        position: absolute;
        top: 51%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 18px;
        height: 18px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        opacity: 0;
        transition: opacity .2s; }
    &:hover {
        svg {
            fill: $neutrals2; } }
    &.active {
        svg {
            opacity: 0; }
        &:before {
            opacity: 1; } }
    @include dark {
        background: $neutrals2;
        &:hover {
            svg {
                fill: $neutrals8; } } } }

.button {
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%);
    color: $white; }

.link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: white;
    height: 100%;
    &:hover {
 }        // margin: 10px
    @include dark {
        color: $neutrals8; } }

.body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 6px;
    padding-bottom: 0; }


.line {
    display: flex;
    align-items: flex-start;
    margin-top: auto; }

.title {
    padding: 0px 72px 5px 30px;
    margin-left: -15%;
    margin-top: -36px;
    color: black;
    @include redhat; }

.price {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    margin-top: 7%;
    align-items: center;
    h4 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
        color: black; }
    p {
        font-size: 10px;
        color: black;
        margin-bottom: 0;
        font-weight: 600; } }

.users {
    display: flex;
    margin-right: auto; }

.avatar {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    color: $white;
    border-radius: 50%;
    // border: 2px solid $neutrals8
    @include dark {
        // border-color: $neutrals2
        color: $neutrals8; }
    img {
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 10px; }
    &:not(:first-child) {
        margin-left: -8px; } }

.counter {
    color: grey;
    display: flex;
    @include redhat; }


.foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid $neutrals6;
    @include caption-2;
    color: $neutrals4;
    @include dark {
        border-color: $neutrals3; } }

.status {
    svg {
        margin: -2px 4px 0 0;
        fill: $neutrals4; }
    span {
        font-weight: 600;
        color: $neutrals3;
        @include dark {
            color: $neutrals8; } } }
.ethereumimg {
    height: 31px;
    float: left;
    margin-left: -10px;
    margin-top: 3px; }
.polygonimg {
    height: 25px;
    float: left;
    margin-left: -3px;
    margin-top: 9px;
    margin-right: 6px; }
.ethereum {
    width: 50%;
    margin-top: auto; }
.bidding {
    margin-left: auto;
    font-size: 12px;
    margin-bottom: 3px; }
.tier {
    margin-left: auto;
    i {
        color: #EE9200;
        margin-right: 3px;
        font-size: 12px; }
    svg {
        color: #37d737de;
        height: 16px; }
    span {
        font-size: 10px; } }
.of {
    @include redhat;
    position: absolute;
    // right: 0
    margin: 1px 10px 10px 10px;
    img {
            height: 40px;
            border-radius: 50%; } }
.gender {
    height: 20px !important;
    display: flex;
    margin: 0 auto; }
.stock {
    color: black;
    @include redhat;
    display: flex;
    margin-left: -3px;
    @include m {
        margin-left: -6px; } }

.bitNow {
    background-color: #FEB032;
    border: 1px solid #FFB033;
    color: black;
    @include redhat;
    font-weight: bold;
    padding: 3px 15px;
    margin-left: auto;
    height: fit-content; }
.calendar {
    background-color: #FEB032;
    border: 1px solid #FFB033;
    color: black;
    @include redhat;
    padding: 3px 15px;
    height: fit-content;
    margin: 5px auto;
    width: 100%; }
.season {
    text-align: center;
    @include redhat; }
.size {
    margin-left: auto;
    margin-top: -16%;
    @include redhat; }
.available {
    margin-left: auto;
    display: flex;
    color: $white;
    font-size: 12px;
    text-align: end;
    font-weight: 100; }
.availablebutton {
    border: 1px solid #FFB033;
    color: $white;
    padding: 6px 10px;
    margin-left: 3px;
    height: 30px;
    font-size: 12px;
    @include redhat;
    margin-left: auto;
    display: block; }
.buyNow {
    border: 1px solid #FFB033;
    background-color: #EE9200;
    color: black;
    padding: 6px 10px;
    margin-left: 3px;
    height: 30px;
    font-size: 12px;
    @include redhat;
    font-weight: bold;
    margin-left: auto;
    display: block; }
.availabletext {
    line-height: 98%;
    @include redhat;
    font-size: 13px;
    margin-top: 10px; }

.buyNowDiv {
    margin-left: auto;
    button {
        margin-bottom: -22px; }
    p {
        color: grey;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0px; } }
.head {
    font-weight: bold;
    color: #EE9200; }
.pricefield {
    font-size: 10px; }
.tooltip {
    @include redhat;
    width: 160px;
    img {
        height: 25px;
        width: 25px;
        margin-left: -5px; } }
.Ethdesign {
    margin-right: 2%; }
.titledesign {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    padding: 5px 89px;
    justify-content: left;
    margin: -79px 5px 11px -84px;
    color: black;
    @include redhat;
    font-weight: 350;
    @include x {
        font-size: 12px; }
    @include d {
        font-size: 12px;
        padding: 5px 98px;
        margin: -79px 5px 11px -93px; }
    @include t {
        font-size: 12px;
        margin: -66px 5px 11px -93px; }
    @include m {
        font-size: 12px;
        margin: -76px 5px 11px -93px; } }


.citydesign {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    // margin-right: 11px;
    // margin-top: -8%;
    margin: -16px 5px 0px 100px;
    color: black;
    font-size: 13px;
    @include redhat;
    font-weight: 400;
    @include x {
        font-size: 12px;
        margin: -16px 4px 0px 100px; }
    @include d {
        font-size: 12px; }
    @include t {
        font-size: 12px; }
    @include m {
        font-size: 12px; } }


.pricedesign {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    margin-top: -31%;
    margin-right: 5px;
    color: black;
    @include redhat;
    font-weight: 400;
    font-size: 13px;
    img {
        @include d {
            width: 10px; }
        @include x {
            width: 10px; }
        @include m {
            width: 10px; } }

    @include x {
        font-size: 12px;
        margin: -76px 5px 11px -84px; }
    @include d {
        font-size: 12px;
        margin: -76px 5px 11px -84px; }
    @include t {
        font-size: 12px;
        margin: -63px 5px 11px -84px; }
    @include m {
        font-size: 12px;
        margin: -70px 5px 11px -84px; } }


.usernamedesign {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin: 21px 90px -3px 4px;
    color: black;
    font-size: 13px;
    @include redhat;
    font-weight: 700;
    @include x {
        font-size: 12px; }
    @include d {
        font-size: 12px;
        margin: 21px 102px -3px 4px; }
    @include t {
        font-size: 12px;
        margin: 21px 102px -3px 5px; }
    @include m {
        font-size: 12px;
        margin: 21px 102px -3px 5px; } }
.collectionName {
    font-size: 15px;
    margin-bottom: 0;
    font-weight: bold;
    text-shadow: 0px 2px 6px #c0c0c0; }
.name {
    font-size: 9px;
    color: black;
    margin-bottom: 0;
    text-shadow: 0px 2px 6px #c0c0c0; }
.eth {
    height: 26px;
    margin-right: 3px; }
.imgFlex {
    display: flex;
    align-items: center; }
.detailscontainer {
    width: 30px;
    height: 30px;
    border: 1px solid;
    border-radius: 8px; }
.collection {
    font-size: 13px;
    font-weight: 400;
    color: black; }

