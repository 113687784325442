@import "../../../styles/helpers";

.section {
    width: 100%;
    @include redhat;
    // margin-bottom: 12px;
    // +d
    //     min-height: 576px
    @include m {
        margin-top: 100px;
        text-align: center; }
    @include dark {
        border-color: $neutrals3; } }

.wrap {
    position: relative;
    z-index: 3;
    width: 55%;
    @include m {
        margin-bottom: 40px;
        width: 100%; } }

.stage {
    margin-bottom: 12px;
    @include redhat;
    font-size: 20px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    color: black;
    @include d {
        font-size: 16px;
        align-items: center; }
    @include m {
        font-size: 15px;
        align-items: center; } }

.title {
    @include redhat;
    margin-bottom: 55px;
    width: 100%;
    font-size: 52px;
    font-weight: bold;
    line-height: 1.3;
    color: black;
    @include d {
        font-size: 34px; }
    @include m {
        font-size: 30px;
        margin-bottom: 25px; }
    @include dark {
        color: $neutrals7; } }

.text {
    margin-bottom: 40px;
    @include redhat;
    font-size: 18px;
    line-height: 1.5;
    color: black;
    font-weight: 100;
    @include m {
        font-size: 15px; } }
.btns {
    display: flex;
    @include m {
        display: block; } }


.button {
    height: fit-content;
    padding: 9px 30px;
    font-size: 15px; }
.button1 {
    height: fit-content;
    display: block;
    margin: 5% auto 0;
    padding: 10px 30px;
    // font-weight: 100;
    @include m {
        margin: 25px auto 0; } }

.gallery {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    width: 684px;
    transform: translateY(-50%);
    pointer-events: none;
    @include x {
        right: calc(50% - 520px);
        width: 550px; }
    @include d {
        right: calc(50% - 440px);
        width: 420px; }
    @include m {
        position: static;
        transform: translateY(-20%); }
    @include s {
        width: auto;
        margin: 0 0 0 -22px; } }

.preview {
    // &:first-child
    //     position: relative
    //     z-index: 2
    // &:nth-child(2)
    //     right: -11%
    //     bottom: -3.5%
    //     z-index: 3
    //     width: 47%
    // &:not(:first-child)
 }    //     position: absolute


.container1 {
    display: flex;
    // justify-content: space-between
    margin-top: 6%;
    @include m {
        flex-direction: column; } }
.manImg {
    width: 100%;
    // margin-left: -24%
    position: relative;
    z-index: 1;
    height: 100%;
    @include m {
        width: 100%; } }
.ellipse {
    position: absolute;
    top: 25%;
    right: 0;
    z-index: 0; }
.heading {
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    // margin-bottom: 5%
    @include m {
        font-size: 30px; } }

.section {
    // overflow: hidden
    padding-bottom: 140px;
    @include x {
        padding-bottom: 112px; }
    @include m {
        padding-bottom: 64px; } }

.top {
    display: flex;
    align-items: center;
    margin-bottom: 54px;
    @include m {
        display: block;
        margin-bottom: 32px; } }

.stage {
    @include body-bold-1;
    color: $neutrals4; }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals5; }

.field {
    .dropdown {
        min-width: 256px;
        @include m {
            min-width: 100%; } } }

.box {
    margin-right: auto;
    @include m {
        margin: 0 0 16px; }
    .dropdown {
        min-width: auto;
        margin-left: -24px;
        @include m {
            display: inline-block; } } }

.wrapper {
    margin: 0 -16px;
    overflow: hidden;
    @include m {
        margin: 0 -4px; } }

.item {
    // border: 2px double transparent
    // border-radius: 10px
    // background-image: linear-gradient(#fff,#fff),radial-gradient(circle at top,#4ce0ff,#777e90) !important
    // background-origin: border-box !important
    // background-clip: content-box,border-box !important
    // width: 100%
    // padding: 0px
    // background: $white
    @include dark {
        background: $neutrals1; }
    &:hover {
        // box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12)
        .avatar {
            // width: 80px
            @include d {
 } } } }                // width: 64px

.head {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $neutrals6;
    @include dark {
        border-color: $neutrals3; } }

.rating {
    display: flex;
    align-items: center;
    height: 24px;
    margin-right: auto;
    padding: 0 8px;
    border-radius: 12px;
    @include caption-bold-2;
    color: $neutrals8; }

.icon {
    margin-right: 4px;
    img {
        width: 16px; } }

.control {
    display: flex; }

.button {
    svg {
        fill: $neutrals5;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $blue; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.body {
    text-align: center;
    margin: 10px;
    background: $white;
    max-width: 300px;
    min-width: 300px; }

.avatar {
    position: relative;
    // width: 64px
    margin: 0 auto 12px;
    transition: width .2s;
    height: 100%;
    overflow: hidden;
    max-height: 279px;
    // min-height: 279px
    border-radius: 10px;
    & > img {
        // position: absolute
        // top: 0
        // left: 0
        width: 100%;
        max-height: 279px;
        min-height: 279px;
        // height: 100%
        // object-fit: cover
 }        // border-radius: 10px
    // &:before
    //     content: ""
    //     display: block
 }    //     padding-bottom: 100%

.reward {
    position: absolute;
    right: -4px;
    bottom: -4px; }

.name {
    margin-bottom: 2px;
    font-weight: 500; }

.price {
    @include caption-2;
    color: $neutrals4;
    span {
        font-weight: 600;
        color: $neutrals2;
        @include dark {
            color: $neutrals6; } } }
.collection {
    display: flex;
    align-items: center;
    width: 100%; }
.collectioName {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-left: 10px;
    justify-content: center;
    // width: 60%
    h4 {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 0;
        color: black;
        text-align: start;
        line-height: 1.2; }
    p {
        font-size: 11px;
        font-weight: 100;
        margin-bottom: 0;
        color: black; } }
.email {
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 17% 7%;
    @include m {
        margin: 0 0 20%; } }
.input {
    width: 100%;
    position: absolute;
    background: #DAD8EE;
    border: none;
    border-radius: 50px; }
.in {
    position: absolute;
    right: 1%;
    top: 6px; }
.updates {
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    margin: 4% 0 1%;
    @include m {
        font-size: 30px; } }
.feature {
    text-align: center;
    font-size: 18px;
    margin-bottom: 1.5%;
    @include m {
        font-size: 15px;
        margin-bottom: 3%; } }
.eth {
    height: 26px !important;
    width: 26% !important;
    margin-top: 8px;
    margin-right: 3px;
    max-height: 26px !important;
    min-height: 26px !important; }
.prices {
    display: flex;
    text-align: start;
    justify-content: flex-end;
    width: 30%;
    h4 {
        font-size: 12px;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 3px; }
    p {
        color: black;
        font-size: 10px;
        margin-bottom: 0; } }
.collectionImg {
    // width: 20%
    height: 50px;
    border-radius: 50%; }
.imgFlex {
    display: flex;
    // width: 70%
    img {
        max-height: 60px !important;
        min-height: 50px !important; } }
.nav {
    display: flex;
    margin: 4% 0;
    @include m {
        overflow: auto;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none; }
        &:before,
        &:after {
            // content: ""
            flex-shrink: 0;
            width: 32px;
            height: 1px; } } }

.link {
    flex-shrink: 0;
    margin: 0 20px 0 0;
    padding: 6px 12px;
    border-radius: 30px;
    background: none;
    font-family: 'RedHatDisplay';
    font-size: 16px;
    color: black;
    transition: all .2s;
    border: 1px solid black;
    font-weight: 100;
    @include m {
        margin: 0; }
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: #4F42C9;
        color: $neutrals8;
        border: none;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        @include m {
            margin-right: 12px; } } }
.row {
    // borderRadius:   10px
    // --bs-gutter-x: 1.5rem;
    // --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin: -92px -16px 0;
    @include m {
        margin: 0 30px; }
    @include s {
        margin: 0 10px; } }
.loader {
    margin: 0 auto; }
.errorMsg {
    color: red;
    text-align: center; }
