@import "../../../styles/helpers";

.group {
    flex: 0 0 50%;
    &:first-child {
        padding-right: 50px;
        @include m {
            padding-right: 0; } }
    &:nth-child(2) {
        padding-left: 16px;
        @include m {
            padding-left: 0; } }
    &:not(:last-child) {
        @include m {
            border-bottom: 1px solid $neutrals6;
            @include dark {
                border-color: $neutrals3; } } }
    &.active {
        @include m {
            .head {
                svg {
                    transform: translateY(-50%) rotate(180deg); } }
            .menu {
                display: flex; } } } }

.head {
    margin-bottom: 40px;
    font-weight: bold;
    font-size: 26px;
    color: $white;
    margin-top: -20px;
    @include redhat;
    &:hover {
        color: #4F42C9; }
    @include t {
        font-size: 16px;
        margin-bottom: 32px; }
    @include m {
        position: relative;
        font-weight: 700px;
        font-size: 18px;
        @include hairline-2; }
    svg {
        display: none;
        @include m {
            display: block;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            fill: $neutrals4;
            transition: transform .2s; } } }

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include m {
        display: none;
        padding-bottom: 32px; } }

.link {
    @include button-2;
    @include redhat;
    font-weight: 400;
    font-size: 18px;
    color: $white;
    transition: color .2s;
    &:hover {
        color: #4F42C9; }
    &:not(:last-child) {
        margin-bottom: 24px; }
    @include m {
        font-size: 12px; } }

