@import "../../styles/helpers";

.card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background: white !important;
    height: 300px;
    width: 250px;
    padding: 10px;
    margin: 20px;
    margin-top: 51px;
    flex-direction: column;
    border: 1px solid#ddd;
    border-radius: 12px;
    transition: 0.7s all ease-in-out;
    @include m {
      transition: none;
      margin: 20px auto;
      width: 300px; } }

// .card:hover
//     transform: scale(1.1)
//     background: #ddd
//     // box-shadow: 5px 5px 30px 0px #F2567A
//     +m
//       transform: none

.title {
  line-height: 1.2;
  width: 50%;
  h4 {
    @include redhat;
    margin-right: auto;
    // margin-bottom: 15px
    // height: 40px
    overflow-wrap: break-word;
    // margin-top: 15px
    padding-top: 5px;
    // margin-left: 5px
    font-size: 12px;
    font-weight: 400;
    color: black; }
  p {
    font-size: 10px;
    color: black;
    margin: 0;

    @include dark {
        color: black; } } }

.mainImage {
    height: 220px;
    width: 100%;
    border-radius: 12px; }


.avatarWrapper {
  position: relative;
  top: 20px;
  width: 60px;
  height: 60px;
  margin-top: -20px; }


.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%; }

.icon {
  position: absolute;
  right: 2px;
  bottom: 4px;
  border-radius: 50%;
  background: #EE2151;
  width: 15px;
  height: 15px;
  display: grid;
  text-align: center;
  line-height: 15px;
  color: white;
  font-size: 10px;
  font-weight: bolder; }

.tokenPrice {
    color: black; }
.wrapper {
  display: flex;
  margin-top: 6px;
  align-items: center; }
.qty {
  margin-left: auto;
  color: black; }
.loader {
  margin-left: 60px;
  margin-top: 25px; }
.loader1 {
    // position: absolute;
    // top: -70px;
    // right: 0;
    // left: 0;
    margin: 100px auto; }
.loader2 {
    margin: 22px auto; }
.link {
    cursor: pointer; }
