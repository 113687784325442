@import "../../styles/helpers";

.item {
    display: flex;
    @include m {
        flex-direction: column; } }

.register {
    margin-top : 32px;
    margin-bottom : 16px; }

.title {
    margin-top: 8px;
    margin-left: 16px; }

.image {
    width: 35%;
    margin-right: 20px;
    height: 100%;
    max-width: 300px;
    @include m {
        width: 100%; }
    svg {
        fill: $neutrals8; } }

.info {
    // margin-top: 16px
    margin-bottom: 16px;
    @include body-bold-2; }

.button {
    font-size: 15px;
    padding: 9px 30px;
    height: fit-content;
    width: fit-content;
    display: block;
    margin: 20px auto 0;
    @include m {
        margin: 0 auto; }
    &:not(:last-child) {
        margin-bottom: 8px; } }

.field {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include m {
                margin-bottom: 20px; } } }

.note {
    margin-left: 8px;
    font-size: 12px;
    color: $neutrals4; }

.alert {
  text-align: center;
  color: #f44336;
  margin-bottom: 16px;
  font-size: 12; }

.success {
  text-align: center;
  color: green;
  margin-bottom: 16px;
  font-size: 16px; }

.spinner {
    margin-left: 8px; }

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s; }


.closebtn:hover {
  color: black; }

.avatar,
.icon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 8px; }

.avatar {
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }
.text {
    font-weight: 500;
    @include m {
        margin-top: 10px; } }

.wallet {
    width: 280px;
    cursor: pointer;
    border: 2px solid $neutrals6;
    border-radius: 12px;
    height: 55px;
    @include dark {
        border-color: $neutrals3; }
    @include t {
        width: 260px; }
    @include m {
        width: 100%;
        margin-bottom: 12px; } }

.modal {
    padding: 0 42px;
    @include m {
        padding: 0 5px; } }

.metamask {
    height: 40px;
    width: 40px;
    margin: 5px 25px; }

.iconimg {
    float: right;
    margin-top: -25px; }

.delevery {
    display: flex;
    margin-right: 38px; }

.buttons {
    display: flex;
    width: 68%;
    margin-left: auto; }

.leftbtn {
    width: auto !important;
    margin-right: auto; }

.rightbtn {
    width: auto !important;
    margin-left: auto; }
.deleveryModal {
    max-width: 1000px !important;
 }    // background-color: blue
.priceInfo {
    display: flex;
    font-size: 14px;
    margin: 10px 0 15px;
    font-weight: bold;
    align-items: baseline;
    @include m {
        font-size: 13px; }
    label {
        margin-right: 10px; } }
.collectioName {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #4F42C9;
    @include m {
        margin-top: 10px; } }
.connect {
    @include redhat; }
.loader {
    margin-left: 20px; }
.Price {
    font-size: 14px;
    margin: 10px 0 15px;
    font-weight: bold;
    width: 250px;
    @include m {
        font-size: 13px;
        width: auto; }
    label {
        margin-right: 10px; } }

.css-1xcc5ro {
  margin: -5px 5px -5px 10px; }
.left1 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -41px;
    margin-left: 85px;
    @include m {
       margin-left: 101px; } }
.btnContent {
    width: 60%; }
.soldOut {
    text-align: center;
    font-size: 16px;
    color: #4F42C9;
    font-weight: bold; }
.loader {
    margin: 0 auto; }
