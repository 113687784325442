@import "../../styles/helpers";

.header {
    position: fixed;
    width: 100%;
    background-color: $neutrals8;
    z-index: 20;
    padding: 10px 0;
    border-bottom: 1px solid $neutrals6;
    @include m {
        padding: 48px 0 24px;
        border: none; }
    @include dark {
        border-color: $neutrals3;
        background-color: $neutrals1; } }

.container {
    display: flex;
    align-items: center;
    & > .button {
        margin-right: 12px;
        @include m {
            display: none; } } }

.logo {
    position: relative;
    z-index: 12;
    display: inline-block;
    flex-shrink: 0;
    width: 128px;
    margin-right: 32px;
    @include d {
        margin-right: auto; }
    img {
        width: 50%; } }

.wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    @include d {
        flex-grow: 0; }
    @include m {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        align-items: stretch;
        height: 100vh;
        margin: 0;
        padding: 140px 32px 40px;
        box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2);
        background: $neutrals8;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        @include dark {
            background: $neutrals1; }
        &.active {
            visibility: visible;
            opacity: 1; } }
    .button {
        display: none;
        @include m {
            display: inline-flex;
            margin-top: 16px; } } }

.nav {
    display: flex;
    margin-right: auto;
    border-left: 2px solid $neutrals6;
    @include d {
        display: none; }
    @include m {
        display: flex;
        flex-direction: column;
        margin: 0 0 40px;
        border: none; }
    @include dark {
        border-color: $neutrals3; } }

.link {
    font-size: 18px;
    margin-left: 32px;
    @include dm-sans;
    line-height: 40px;
    color: $neutrals4;
    transition: color .2s;
    @include m {
        margin-left: 0;
        font-size: 24px;
        line-height: 64px; }
    &:hover,
    &.active {
        color: $cyan; }
    &:nth-child(n+4) {
        display: none;
        @include m {
            display: block; } } }

.search {
    position: relative;
    flex-shrink: 0;
    width: 256px;
    margin-right: 24px;
    @include t {
        display: none; }
    @include m {
        display: block;
        width: 100%;
        margin: auto 0 0; } }

.input {
    width: 100%;
    height: 40px;
    padding: 0 42px 0 16px;
    background: none;
    border: 2px solid $neutrals6;
    border-radius: 8px;
    @include poppins;
    @include caption-2;
    transition: border-color .2s;
    @include placeholder {
        color: $neutrals4; }
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 42px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $cyan; } } }

.notification {
    margin-right: 24px;
    @include m {
        margin-right: 20px; } }

.burger {
    display: none;
    @include m {
        display: block;
        position: relative;
        margin-left: 20px;
        width: 32px;
        height: 32px;
        background: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        &.active {
            &:before {
                transform: translateY(0) rotate(-45deg); }
            &:after {
                transform: translateY(0) rotate(45deg); } }
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 16px;
            left: 6px;
            width: 20px;
            height: 2px;
            background: $neutrals4;
            border-radius: 2px;
            transition: transform .2s; }
        &:before {
            transform: translateY(-4px); }
        &:after {
            transform: translateY(3px); } } }
.user {
    margin-left: 70%; }
