@import "../../styles/helpers";

.section {
    padding: 15px 0;
    @include x {
        padding: 80px 0; }
    @include m {
        padding: 64px 0; } }

.container {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.bg {
    position: relative;
    flex-grow: 1;
    margin-right: 96px;
    @include x {
        margin-right: 64px; }
    @include d {
        margin-right: 32px; }
    @include t {
        margin: 0 0 32px; } }

.details {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 384px;
    @include d {
        width: 336px; }
    @include t {
        width: 100%; } }

.preview {
    position: relative;
    height: 500px;
    background-color: #000000be;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 16px;
    img,video {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 100%; } }

.categories {
    position: absolute;
    top: 24px;
    left: 24px;
    display: flex;
    z-index: 1;
    flex-wrap: wrap;
    margin: -8px 0 0 -8px; }

.category {
    margin: 8px 0 0 8px; }

.options {
    position: absolute;
    top: auto;
    left: 50%;
    bottom: 25px;
    transform: translateX(-50%); }

.title {
    margin-bottom: 8px;
    margin-top: 20px; }

.cost {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px; }

.price {
    @include dm-sans;
    font-size: 16px;
    line-height: 30px;
    &:not(:last-child) {
        margin-right: 8px; } }

.counter {
    @include button-1;
    color: $neutrals4; }

.info {
    margin-bottom: 40px;
    @include body-2;
    color: $neutrals4;
    a {
        text-decoration: underline;
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; }
        &:hover {
            text-decoration: none; } } }

.nav {
    display: flex;
    margin-bottom: 32px;
    padding: 6px;
    border-radius: 20px;
    margin-top: 25px;
    box-shadow: inset 0 0 0 2px $neutrals6;
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals3; } }

.link {
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    margin-bottom: 1px;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.users {
    margin-bottom: auto; }

.control {
    margin-top: 40px;
    @include m {
        margin-top: 32px; } }

.tags {
    margin-bottom: 20px; }

.tag {
    background-color: #e6e6e6;
    border-radius: 30px;
    padding: 3px 16px;
    color: #4f5566;
    border: none;
    margin-bottom: 8px;
    margin-left: 10px; }

.description {
    font-size: 14px;
    margin: 10px 0; }

.edition {
    font-weight: 600;
    font-size: 16px;
    color: #000;
    @include dark {
        color: $neutrals6; } }

.btns {
    display: flex;
    flex-direction: column;
    .button {
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 8px; } } }

.loader {
    margin: 10% 50%; }

.search {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    margin-right: 24px;
    @include t {
        display: none; }
    @include m {
        display: block;
        width: 100%;
        margin: auto 0 0; } }

.input {
    width: 100%;
    height: 40px;
    padding: 0 42px 0 16px;
    background: none;
    border: 2px solid $neutrals6;
    border-radius: 8px;
    @include poppins;
    @include caption-2;
    transition: border-color .2s;
    @include placeholder {
        color: $neutrals4; }
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 42px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $cyan; } } }

.allComments {
    width: 100%;
    margin: 5px 0;
    padding: 5px; }


.commentItem {
    display: flex; }

.user {
    height: 45px;
    width: 45px;
    border-radius: 50%; }
.text {
    margin-top: 10px;
    margin-left: 20px; }
.load {
    text-align: right;
    cursor: pointer;
    &:hover {
        color: #292ff9; } }
