@import "../../../styles/helpers";

.section {
    padding: 0px 0;
    margin-bottom: 50px;
    @include redhat;
    @include x {
 }        // padding: 80px 0
    @include m {
        padding: 64px 0; } }

.container1 {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.details {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    // flex-shrink: 0
    width: 50%;
    @include w {
 }        // width: 600px

    @include d {
 }        // width: 336px
    @include t {
        width: 100%; } }

// .bg
//     position: relative
//     width: 50%
//     flex-grow: 1
//     margin-right: 80px
//     +x
//         margin-right: 64px
//     +d
//         margin-right: 32px
//     +t
//         margin: 0 0 32px
// .preview
//     position: relative
//     height: 400px
//     // width: 100%
//     border-radius: 20px
//     // background-color: #000000be;
//     background-repeat: no-repeat;
//     background-size: 100% 100%;
//     overflow: hidden
//     +d
//         margin-top: 1px
//         width: 500px
//         height: 500px
//     +m
//         margin-top: 2px
//         width: 330px
//         height: 300px

// .image
//     width: 100%
//     height: 100%
//     object-fit: fill
//     // max-height: 389px
//     // min-height: 389px
//     // display: block
//     // margin: 0 auto
//     border-radius: 20px
//     +m
//         width: 100%
//         min-height: auto
//         max-height: fit-content
//         height: auto

.bg {
    position: relative;
    flex-grow: 1;
    margin-right: 100px;
    width: 50%;
    @include x {
        margin-right: 64px; }
    @include d {
        margin-right: 32px; }
    @include t {
        margin: 0 0 32px; } }
.preview {
    position: relative;
    height: 100%;
    // background-color: #000000be;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 16px;
    img,video {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        border-radius: 20px;
        width: 100%; } }


.categories {
    position: absolute;
    top: 24px;
    left: 24px;
    display: flex;
    z-index: 1;
    flex-wrap: wrap;
    margin: -8px 0 0 -8px;
    color: white;
    font-size: 26px;

    font-weight: bold; }

.category {
    margin: 8px 0 0 8px; }

.options {
    position: absolute;
    top: auto;
    left: 50%;
    bottom: 25px;
    transform: translateX(-50%); }

.title {
    margin-bottom: 8px;
    margin-top: 10px;
    font-size: 28px;
    word-break: break-word;
    margin-left: 6px;
    @include m {
        font-size: 30px; }
    @include dark {
        color: $neutrals8; } }

.title1 {

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px; }


.title2 {
    height: 60px;
    width: 60px; }

.cost {
    display: flex;
    align-items: center;
    font-size: 10px;
    margin-bottom: 20px;
    margin-top: 20px; }

.price1 {
    height: auto;
    width: auto;
    padding: 10px;
    // font-size: 12px
    font-weight: 600;
    border-radius: 30px;
    text-align: center;
    color: white;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #EE2151;
    background: #EE2151;
    @include w {
        font-size: 12px; }
    &:not(:last-child) {
        margin-right: 8px; } }

.counter {
    @include button-1;
    color: $neutrals4; }

.info {
    margin-bottom: 40px;

    color: black;
    font-size: 14px;
    a {
        text-decoration: underline;
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; }
        &:hover {
            text-decoration: none; } } }

.nav {
    display: flex;
    margin-bottom: 32px;
    padding: 6px;
    border-radius: 20px;
    margin-top: 25px;
    box-shadow: inset 0 0 0 2px $neutrals6;
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals3; } }

.link {
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    margin-bottom: 1px;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.users {
    margin-bottom: auto; }

.control {
    margin-top: 40px;
    @include m {
        margin-top: 32px; } }

.tags {
    // margin-bottom: 10px
    margin-top: 20px; }

.tag {
    background-color: #D2E3E3;
    border-radius: 30px;
    padding: 3px 16px;
    color: black;
    border: none;
    font-size: 12px;
    font-weight: 500;
    // margin-bottom: 8px
 }    // margin-left: 10px

.description {
    font-size: 14px;
    margin: 10px 0; }

.edition {
    font-weight: 600;
    font-size: 16px;
    color: #000;
    @include dark {
        color: $neutrals6; } }

.btns {
    display: flex;
    flex-direction: column;

    // padding: 5px
    .button {
        width: 50%;
        margin: -17px auto 0;
        &:not(:last-child) {
            margin-bottom: 8px; } } }

.loader {}


.search {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    margin-right: 24px;
    @include t {
        display: none; }
    @include m {
        display: block;
        width: 100%;
        margin: auto 0 0; } }

.input {
    width: 100%;
    height: 40px;
    padding: 0 42px 0 16px;
    background: none;
    border: 2px solid $neutrals6;
    border-radius: 8px;
    @include poppins;
    @include caption-2;
    transition: border-color .2s;
    @include placeholder {
        color: $neutrals4; }
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 42px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $cyan; } } }

.allComments {
    width: 100%;
    margin: 5px 0;
    padding: 5px; }


.commentItem {
    display: flex; }

.user {
    height: 45px;
    width: 45px;
    border-radius: 50%; }
.text {
    margin-top: 10px;
    margin-left: 20px;
    @include dark {
        color: $neutrals8; } }
.load {
    text-align: right;
    cursor: pointer;
    &:hover {
        color: #292ff9; } }
.collapse {
    background-color: $neutrals8 !important;
    margin-top: 20px;
    @include dark {
        background-color: $neutrals1 !important;
        color: $neutrals8 !important;
        border-color: #B1B5C3; } }
.field {
    font-weight: bold;
    @include dark {
        color: $neutrals8; } }

.chart {
    width: fit-content;
    padding: 5px;
    border: 1px solid #D9D9D9;
    width: 100%;
    @include m {
        width: fit-content;
        padding: 5px;
        border: 1px solid #D9D9D9;
        width: 100%; } }



.checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  z-index: 999; }

.btnclose {
    font-size: 1.5rem;
    font-weight: bold;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 9991;
    color: #fff;
    background: #4F42C9 ;
    border-radius: 50px;
    width: 36px;
    height: 36px;
    line-height: 5px;
    opacity: 1;
    text-align: center; }

.btnx {
    margin: -5px 0 0; }


.avatar {
        width: 25%;
        height: 25%;
        margin: -20px -5px 24px;
        object-fit: cover;
        border-radius: 50%; }
.parg {
    font-size: 16px;
    color: #777E90;
    font-weight: 500;
    margin: -62px 90px 24px; }

.bold1 {
  font-size: 16px;
  color: black;
  font-weight: bold;
  position: relative;
  top: -48px;
  right: -90px; }

.bold2 {
  font-size: 16px;
  color: black;
  font-weight: bold;
  position: relative;
  top: -52px;
  right: -90px; }

.qty {
  color: black;
  font-weight: bold;
  font-size: 16px; }

.avai {
  font-size: 16px;
  color: #4F42C9;
  font-weight: bold;
  position: relative;
  top: -31px;
  right: -350px; }

.formcontrol {
    padding: 8px;
    margin-top: -30px;
    border: none;
    border: solid 1px #cccccc;
    background: none;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    height: auto;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    color: #333; }



.maincheckout {
    position: relative;
    border-radius: 8px;
    background: $white;
    padding: 30px;
    width: 100%;
    max-width: 500px;
    height: max-content;
    margin: 30px 0 0; }
.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    p {}
    font-size: 16px;
    color: #777E90;
    font-weight: 500; }

h3 {
    font-size: 24px;
    margin-bottom: 25px; }

.subtotal {
    color: black;
    font-size: 15px; }


.detailcheckout {
    display: flex;
    justify-content: space-between;
    align-items: center; }
.listcheckout {
    width: 100%;
    display: flex;
    align-items: left;
    flex-direction: column; }
.thumb {
    width: 90px; }

.description {
    padding: 15px;
    h3, p {}
    margin-bottom: 0;
    input {
      margin: 10px 0 15px; } }

.agrement {
    display: flex;
    align-items: center;
    label {
      padding-left: 5px; } }


.btnmain {
    width: 100%;
    margin: 30px auto;
    margin-bottom: 15px !important; }

.bold {
  color: black;
  font-weight: bold; }



.nftattr {
    display: block;
    padding: 10px;
    text-align: center;
    background: rgba(64,63,131,.15);
    border: solid 1px rgba(0, 0, 0, .15);
    border-radius: 5px;
    margin-bottom: 8px;
    h5 {
      color: white;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px; }

    h4 {
      font-size: 15px;
      font-weight: 700;
      color: #0d0c22;
      margin-bottom: 0; }

    span {
      font-size: 13px;
      color: #727272; } }



.btncheckout {
    width: 100%;
    margin: 30px auto;
    margin-bottom: 15px !important;
    display: block;
    width: max-content;
    text-align: center;
    color: #fff !important;
    background:  #4F42C9;
    border-radius: 6px;
    letter-spacing: normal;
    outline: 0;
    font-weight: 500;
    text-decoration: none;
    padding: 8px 40px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease; }

.itemActivity {
        width:  50%; }

.financials {
    width: fit-content;
    padding: 5px;
    border: 1px solid #D9D9D9;
    width: 100%;
    @include m {
        width: fit-content;
        padding: 5px;
        border: 1px solid #D9D9D9;
        width: 100%; } }

.shareholding {
    width: fit-content;
    padding: 5px;
    border: 1px solid #D9D9D9;
    width: 100%;
    @include m {
        width: fit-content;
        padding: 5px;
        border: 1px solid #D9D9D9;
        width: 100%; } }
.priceRow {
    // display: flex
    // width: 100%
    h4 {
        color: black;
        font-size: 25px;
        margin-bottom: 0;
        margin-top: -4px; } }
.of {
    // padding-top: 11px
    color: #4a4a4a;
    font-size: 15px;
    padding: 10px;
    @include m {
        padding-left: 7px; } }
.salesEnd,.detailHead,.priceHead {
    color: #4F42C9;
    // text-shadow: 2px 2px 25px #C43C2A
    font-size: 18px;
    padding: 7px;
    // width: 30%
    font-weight: bold;
    @include m {
        font-size: 18px; } }
.price {

    font-size: 15px;
    text-align: justify;
    padding: 9px 44px;
    @include m {
        padding: 7px;
        font-size: 15px; }
    p {
        margin-bottom: 0;
        margin-left: 10px; } }

.eth {
    height: 26px;
    margin-right: 5px;
    @include m {
        height: 22px; } }
.detail {
    box-shadow: inset 0px 0px 16px #8f84f9;
    padding: 4px 20px;
    border-radius: 15px;
    transition: all 1s ease-in-out;
    margin-left: 20px;
    width: 50%;
    border: 1px solid white;
    height: fit-content;
    @include m {
        height: fit-content;
        margin-left: 0;
        width: 100%; } }
.detail1 {
    box-shadow: inset 0px 0px 16px #8f84f9;
    padding: 4px 20px;
    border-radius: 15px;
    transition: all 1s ease-in-out;
    margin-right: 20px;
    width: 50%;
    border: 1px solid white;
    height: fit-content;
    @include m {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px; } }

.newcontainers {
    display: flex;
    width: 100%;
    margin-top: 60px;
    // margin-left: -17px
    @include m {
        flex-direction: column; } }
.text1 {
   @include redhat;
   font-weight: bold;
   font-size: 18px;
   margin-bottom: 0; }
.subhead {
    @include redhat;
    font-size: 15px;
    line-height: 29px; }

.textt {
    @include redhat;
    margin: 2px 26px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include m {
        margin: 2px 0; } }

.description {
    margin: -11px 13px;
    font-size: 14px;
    width: 80%;
    @include m {
        margin: -11px 0;
        padding: 15px 0;
        width: 100%; } }

.test {
    @include redhat;
    color: black;
    font-size: 17px;
    margin-top: 9px;
    margin-left: 47px;
    @include m {
        margin-left: 0;
        padding: 0 7px;
        font-size: 15px; } }
.titles {
    display: flex;
    align-items: flex-end;
    @include m {
        font-size: 15px; } }
.headingFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    svg {
        font-size: 20px;
        cursor: pointer; } }
.loader {
    margin: 0 auto;
    z-index: 10;
    position: relative;
    top: 20px; }
.wallet {
    font-size: 20px;
    text-align: center;
    margin: 50px 0 220px; }
