@import "../../../styles/helpers";

.card {
    width: 28%;
    border-radius: 20px;
    background: $neutrals8;
    box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4);
    @include dark {
        background: $neutrals1;
        // box-shadow: inset 0 0 0 2px $neutrals1
        box-shadow: 0px 16px 64px 0 $neutrals3; }
    @include x {
        width: 35%; }
    @include t;
    @include d {
        width: 50%; }
    @include m {
        width: 80%; } }

.body {
    display: flex;
    justify-content: center;
    align-items: center; }



.logo {
    width: 70px;
    margin: 30px 0 0 30px; }
