@import "../../../styles/helpers";

.users {
    margin-top: 20px; }

.collapse {
    background-color: $neutrals8 !important;
    width: 600px;
    @include d {
        width: 490px; }
    @include m {
        width: 310px; }

    @include dark {
        background-color: $neutrals1 !important;
        color: $neutrals8 !important;
        border-color: #B1B5C3; } }
.item {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    // border-bottom: 1px solid $neutrals6
    @include dark {
        border-color: $neutrals3; }
    &:not(:last-child) {
 } }        // margin-bottom: 16px
.chainInfo {
    padding-bottom: 16px;
    // border-bottom: 1px solid $neutrals6
    margin-top: 5px;
    @include dark {
        border-color: $neutrals3; }
    &:not(:last-child) {
        margin-bottom: 16px; } }
.avatar {
    display: flex;
    flex-direction: row;
    align-items: center; }


.reward {
    position: absolute;
    right: -5px;
    bottom: -5px;
    img {
        max-width: 25px; } }

.details {
    flex-grow: 1; }

.position {
    color: $neutrals4; }

.name {
    @include redhat;
    font-weight: bold;
    padding: 0 9px;
    word-wrap: normal; }

.field {
    @include redhat;
    font-weight: bold;
    @include dark {
        color: $neutrals8; } }

.field1 {
    @include redhat;
    position: relative;
    display: flex;
    margin-left: 10px;
    font-weight: bold;
    color: black; }



