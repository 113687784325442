@import "../../styles/helpers";

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals5; }

.input {
    width: 100%;
    height: 60px;
    padding: 0 14px;
    // border-radius: 12px
    // border: 2px solid $neutrals6
    background: none;
    @include redhat;
    @include caption-bold-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    @include placeholder {
        color: $neutrals4;
        font-size: 18px; }
    &:focus {
        border-color: $neutrals4; } }
