@import "../../styles/helpers";

.footer {
    // padding-left: 80px
    // padding-right: 80px
    // margin-top: 5%
    padding-bottom: 20px;
    background-image: url('../../images/footer-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    @include t {
        padding: 0 30px; }
    @include m {
        padding: 0 20px;
        background-image: url('../../images/footer-bg (1).png'); } }

.col {
    @include m {
        display: flex;
        flex-direction: row; } }

.foot {
    margin-top: 5%;
    font-size: 18px;
    text-align: center;
    color: $white;
    @include m {
        display: block;
        padding: 24px 0 32px;
        font-size: 15px; } }



.logo {
    img {
        width: 52%;
        margin-bottom: 5%; } }

.group {
    flex: 0 0 50%;
    &:first-child {
        padding-right: 50px;
        @include m {
            padding-right: 10px; } }
    &:nth-child(2) {
        padding-left: 16px;
        @include m {
            padding-left: 0; } }
    &:not(:last-child) {
        @include m {
            border-bottom: 1px solid $neutrals6;
            @include dark {
                border-color: $neutrals3; } } }
    &.active {
        @include m {
            .head {
                svg {
                    transform: translateY(-50%) rotate(180deg); } }
            .menu {
                display: flex; } } } }

.head {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 18px;
    @include redhat;
    @include t {
        font-size: 16px;
        margin-bottom: 32px; }
    @include m {
        font-weight: 1000;
        font-size: 20px;
        position: relative;
        margin-bottom: 0;
        padding: 32px 32px 32px 0;
        @include hairline-2; }
    svg {
        display: none;
        @include m {
            display: block;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            fill: $neutrals4;
            transition: transform .2s; } } }

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include m {
        display: none;
        padding-bottom: 32px; } }

.link {
    @include button-2;
    @include redhat;
    font-weight: 400;
    color: black;
    transition: color .2s;
    &:not(:last-child) {
        margin-bottom: 24px; }
    @include m {
        font-size: 12px; } }

.link1 {
    margin-top: 30px;
    margin-bottom: 30px; }

.icons {
    display: flex;
    flex-direction: row;
    font-size: 2em;
    gap: 15px;
    i {
        height: 40px;
        width: 40px;
        text-align: center;
        padding-top: 9px;
        border-radius: 50%;
        color: #fff;
        background: #BB1D55; } }
.footerFlex {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    @include m {
        margin-top: 50px; } }
.socialMedia {
    display: flex;
    flex-direction: column;
 }    //gap: 2rem
.images {
    display: flex;
    align-items: center;
    //gap: 2rem
    justify-content: space-between;
    width: 42%;
    @include m {
        width: 60%; } }
.socialImg {
    width: 100%;
    @include m {
        width: 75%; } }
