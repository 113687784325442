@import "../../../../styles/helpers";

.item {
    border-top: 1px solid $neutrals6;
    @include dark {
        border-color: $neutrals3; }
    &.active {
        .head {
            &:before {
                transform: translateY(-50%) rotate(180deg); } }
        .body {
            display: block; } } }

.head {
    position: relative;
    padding: 32px 40px 32px 0;
    @include body-bold-2;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: color .2s;
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 8px;
        width: 10px;
        height: 6px;
        transform: translateY(-50%);
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        transition: transform .2s; }
    &:hover {
        color: $cyan; } }

.body {
    display: none;
    padding-bottom: 32px;
    @include body-2;
    color: $neutrals4; }

.button {
    margin-top: 24px; }
