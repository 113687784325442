@import "../../../styles/helpers";

.inner {
    padding: 48px;
    background: $neutrals8;
    box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.12);
    border-radius: 16px;
    @include d {
        padding: 24px; }
    @include t {
        position: relative;
        width: 352px;
        margin: auto; }
    @include dark {
        background: $neutrals1;
        box-shadow: inset 0 0 0 1px $neutrals3; } }

.close {
    display: none;
    position: absolute;
    top: 20px;
    right: 24px;
    width: 40px;
    height: 40px;
    background: $neutrals8;
    border: 2px solid $neutrals6;
    border-radius: 50%;
    font-size: 0;
    @include t {
        display: inline-block; }
    svg {
        fill: $neutrals2;
        transition: transform .2s; }
    @include dark {
        border-color: $neutrals3;
        background: $neutrals1;
        svg {
            fill: $neutrals8; } } }

.info {
    margin-bottom: 24px;
    @include body-bold-1; }

.preview {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    & > img {
        width: 100%; } }

.link {
    padding: 20px 0;
    color: $neutrals2;
    @include dark {
        color: $neutrals8; } }

.line {
    display: flex;
    align-items: flex-start;
    &:first-child {
        margin-bottom: 12px; } }

.title {
    margin-right: auto;
    padding-top: 1px;
    @include body-bold-2; }

.price {
    flex-shrink: 0;
    margin-left: 12px;
    padding: 0 8px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px $green;
    font-size: 12px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
    color: $green; }

.counter {
    @include dark {
        color: $neutrals6; } }

.users {
    display: flex;
    margin-right: auto; }

.avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $neutrals8;
    @include dark {
        border-color: $neutrals1; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; }
    &:not(:first-child) {
        margin-left: -8px; } }

.foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid $neutrals6;
    @include caption-2;
    color: $neutrals4;
    @include dark {
        border-color: $neutrals3; } }

.status {
    svg {
        margin: -2px 4px 0 0;
        fill: $neutrals4; }
    span {
        font-weight: 600;
        color: $neutrals3;
        @include dark {
            color: $neutrals8; } } }

.clear {
    display: inline-flex;
    align-items: center;
    margin-top: 24px;
    @include button-1;
    color: $neutrals4;
    transition: color .2s;
    svg {
        margin-right: 8px;
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        color: $cyan;
        svg {
            fill: $cyan; } } }
