@import "../../styles/helpers";

.form {
    position: relative;
    background: #DCF9F9;
    border-radius: 12px; }

.input {
    width: 100%;
    height: 45px;
    padding: 0 48px 0 14px;
    border-radius: 12px;
    background: #EFCBD8;
    border: 2px solid #F8DFE8;
    @include redhat;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals4; }
    @include placeholder {
        color: $neutrals4; }
    @include m {
        width: 100%;
        height: 38px;
        padding: 0 48px 0 14px;
        border-radius: 12px;
        background: #EFCBD8;
        border: 2px solid #F8DFE8;
        @include redhat;
        color: $neutrals2;
        transition: border-color .2s;
        @include dark {
            border-color: $neutrals3;
            color: $neutrals8; }
        &:focus {
            border-color: $neutrals4; }
        @include placeholder {
            color: $neutrals4; } } }


.btn {
    position: absolute;
    top: 1px;
    right: 0;
    bottom: 1px;
    width: 60px;
    // height: 42px
    border-radius: 12px;
    background: #BB1D55;
    transition: background .2s;
    svg {
        fill: $neutrals8; }
    &:hover {
        background:#BE2053 {}
        box-shadow: 5px 5px 30px 0px #F2567A; }
    @include m {
        position: absolute;
        top: 1px;
        right: 0;
        bottom: 1px;
        width: 40px;
        height: 36px;
        border-radius: 10px;
        background: #BB1D55;
        transition: background .2s;
        svg {
            fill: $neutrals8; }
        &:hover {}
        background:#BE2053 {}
        box-shadow: 5px 5px 30px 0px #F2567A; } }
