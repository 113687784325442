.logo {
    height: 32px;
    margin: 30px 16px;
    display: flex;
    cursor: pointer;
  }
  .logo b{
    margin: 3px 0 0 13px;
    font-size: 16px;
  }
  .header{
      display: flex;
  }
  .header p{
      font-size: 14px;

  }
  .header img{
      height: 50px;
      width: 50px;
      border-radius: 50%;
   }
  .site-layout-sub-header-background {
    background: #fff;
  }
  
  .site-layout-background {
    background: #fff;
  }
    [data-theme="dark"] .site-layout-sub-header-background {
      background:#fff ;
      color: #353945 !important;
    }
    .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
        color: #353945 !important;
        background: #fff;
    }
    .ant-layout-sider {
        position: relative;
        min-width: 0;
        color: #353945;
        background: #fff;
         transition: all 0.2s; 
        padding: 0px 10px;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: rgb(163, 0, 0);
        border-radius: 100px;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
        background-color: rgb(163, 0, 0);
        border-radius: 100px;
        color: black;
    }
    .ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
        color: #777E90;
        font-weight: 500;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected svg path, .ant-menu-dark .ant-menu-item:hover svg path{
      fill: white;
    }
    .ant-menu-dark .ant-menu-item:hover {
        color: white;
        background-color: rgb(211, 97, 97);
        border-radius: 100px;
    }
    .ant-menu-item:focus-visible, .ant-menu-submenu-title:focus-visible {
        box-shadow: 0;
    }
    .ant-menu-item:active, .ant-menu-submenu-title:active {
        background: transparent;
    }
    .user{
        float: right;
        width:150px
    }
    .inner{
        margin-top: 80px;
        }
        .ant-layout-content{
            background-color: #fcfcfdef;

        }
    /* width */
    .ant-layout-sider::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .ant-layout-sider::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .ant-layout-sider::-webkit-scrollbar-thumb {
    background: silver; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .ant-layout-sider::-webkit-scrollbar-thumb:hover {
    background: grey; 
  }

    