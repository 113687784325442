@import "../../styles/helpers";

.card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background: white !important;
    height: 320px;
    padding: 10px;
    margin-right: 10px;
    flex-direction: column;
    border: solid 1px #3CC5C5;
    border-radius: 12px;
    transition: 0.7s all ease-in-out; }

.card:hover {
    transform: scale(1.1);
    @include m {
      display: none; } }

// .line
//     width: 100%
//     margin-top: 20px
//     line-height: 18px

// .line1
//     display: flex
//     +redhat
//     font-size: 12px
//     color: black
//     justify-content: space-bet
//     margin-top: 10px
//     text-transform: capitalize

// .title
//     +redhat
//     color: black
//     margin-right: auto
//     margin-bottom: 10px
//     overflow-wrap: break-word
//     margin-top: 10px
//     padding-top: 5px
//     font-size: 13px
//     font-weight: 700
//     +dark
//         color: black

.mainImage {
    height: 170px;
    width: 100%;
    border-radius: 12px; }


.avatarWrapper {
  position: relative;
  top: 20px;
  width: 50px;
  height: 50px;
  margin-top: -20px; }

.avatar {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  border-radius: 50%; }

.icon {
  position: absolute;
  right: 0;
  bottom: 4px;
  border-radius: 50%;
  background: #3CC5C5;
  width: 15px;
  height: 15px;
  display: grid;
  text-align: center;
  line-height: 15px;
  color: white;
  font-size: 10px;
  font-weight: bolder; }

.line {
  width: 100%;
  margin-top: 18px;
  @include redhat;
  color: black;
  font-size: 12px;
  text-transform: capitalize; }



.line1 {
  height: 30px;
  display: flex;
  margin-bottom: 18px;
  justify-content: space-between; }

.title {
  display: flex;
  flex-direction: row;
  margin-left: 10px; }


.line2 {
  display: flex;
  justify-content: space-between; }

.eth {
  width: 10px;
  height: 15px;
  margin-right: 3px;
  margin-top: 5px;
  @include w {
    margin-top: 1px; }
  // +d
  //   margin-top: 3px
  // +t
 }  //   margin-top: 3px
