@import "../../../styles/helpers";

.container {
    width: 100%;
    max-width: 1480px;
    margin: 0 auto;
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 10%;

    @include d {
        width: 90%;
        max-width: 1480px;
        margin: 0 auto;
        padding-left: 0.5px;
        padding-right: 80px;
        margin-top: 5%; }
    @include m {
         margin: -10% 5%;
         padding: 100px 50px; }
    // +w
    //     width: 100%
    // +d
    //     width: 100%
    // +t
    //     padding: 0 40px
    // +m
    //     margin: auto
 }    //     padding: 10px 50px 0

.line {
    margin: 0 auto;
    display: block;
    border: solid 2px;
    color: #EE2151;
    width: 230px;
    height: 2px;
    background: #EE2151;
    border-left: none;
    border-left-color: currentcolor;
    border-right: none;
    border-right-color: currentcolor;
    margin: 18px auto 30px;
    @include m {
        margin: 0 auto;
        position: relative;
        border-radius: 10px;
        width: 100px;
        transform: translateY(-3800%); } }


.line1 {
    display: block;
    border: solid 2px;
    color: #F2E4EA;
    width: 2px;
    height: 120px;
    background: #F2E4EA;
    border-radius: 10px;
    margin-top: 38px;
    position: relative;
    left: 30px;

    @include d {
        left: 20px;



 } }        // transform: translateY(-3600%)

.row {
    display: flex;
    gap: 70px;
    margin-top: 5%;
    @include d {
        grid-gap: 15px;
        gap: 50px;
        position: relative;
        left: 5px;
        width: 900px; }
    @include t {
        gap: 15px;
        position: relative;
        left: -14px;
        width: 721px; }

    @include m {
        position: relative;
        flex-direction: column;
        gap: 20px;
        margin: -40% 5%;
        width: 100%; }


    // margin: 10%

    // display: grid
    // grid-template-columns: 50%  50%  50%

    // grid-gap: 80px
    // margin-left: 62px
    // margin-bottom: 80px
    // margin-top: 50px

    // +m
    //     display: flex
    //     flex-wrap: wrap
    //     margin-left: -45px
    //     width: 200%
 }    //     gap: -200px

.feature {
    padding: 40px;
    display: flex;
    flex-direction: row;
    // margin: 50px
    width: 50%;
    background-color: #F2E4EA;
    border-radius: 20px;
    color: black;
    @include d {
        padding: 14px 25px;
        height: 223px; }
    @include a {
        padding: 14px 25px;
        height: 223px; }

    @include m {
        padding: 30px;
        display: flex;
        width: 100%;
        background-color: #F2E4EA;
        border-radius: 20px; } }





.feature:hover {
    color: #ffffff;
    background: #F2567A;
    box-shadow: 5px 5px 30px 0px #F2567A; }



.icon_wallet {}

.text {
    font-size: 20px;
    @include redhat;
    font-weight: 600;
    // color: black
    margin-top: 20px;
    margin-bottom: auto;
    @include d {
        font-size: 17px; }
    @include m {
        font-size: 15px; } }



.text1 {
    font-size: 15px;
    @include redhat;
    @include d {
        font-size: 14px; }
    @include m {
        font-size: 13px; } }
//     color: black

.img {
    height: 64px;
    width: 64px;
    @include m {
        height: 50px;
        width: 50px; } }

.title {
    @include redhat;
    text-align: center;
    @include m {

        transform: translateY(-714%);
        font-size: 15px; }

    @include dark {
        color:  white; } }

.img1 {
    height: 100px;
    color: #ffffff;
    background: #3CC5C5; }

