@import "../../styles/helpers";

.title {
    @include redhat;
    text-align: start;
    font-weight: bold;
    font-size: 37px;
    margin-bottom: 0;
    @include m {
        font-size: 30px;
        margin-bottom: 25px; } }

.container {
    margin-top: 2%;
    @include m {
        margin: 80px auto; } }
