.navigationButtons {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin: 30px 0 50px 0;
    padding-top: 40px;
  }
   
  .navigationButtons a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #4F42C9;
    color: #4F42C9;
    cursor: pointer;
  }
   
  .navigationButtons a:hover {
    color: white;
    background-color: #4F42C9;
  }
   
  .navigationActive a {
    color: white;
    background-color: #4F42C9;
  }
   
  .navigationDisabled a {
    color: grey;
    border: 1px solid grey;
    cursor: not-allowed;
    opacity: 0.6;
    background-color: transparent;
  }
  .navigationDisabled a:hover{
    background-color: transparent;
    color: grey;
  }
.ant-pagination-options-size-changer.ant-select{
  display: none
}
.ant-spin{
  line-height: 0;
}
.ant-spin-spinning{
  position: relative;
  margin-left: 10px;
}
.ant-spin-dot-item{
  background-color: white !important;
}
.ant-spin-dot{
  /* font-size: 16px !important; */
}