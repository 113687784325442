@import "../../styles/helpers";

.container {
    width: 100%;
    max-width: 1480px;
    margin: 0 auto;
    padding: 20px 80px;
    // overflow: hidden
    // +w
    //     padding: 0 100px
    // +d
    //     padding: 0 70px
    @include t {
        padding: 10px 40px; }
    @include m {
        padding: 10px 20px;
 } }        // padding-top: 15px
