.select{
    width: 641px !important;
    height: 48px;
    border-radius: 12px;
}
.react-select__control{
    height: 48px;
    border-radius: 12px !important;
    border-width: 2px !important;
}

.dark .react-select__control{
    border-color: #353945;
    color: #353945 !important;
    background-color: #141416 !important;
}
.light .react-select__control{
    height: 48px;
    border-radius: 12px !important;
    border-color: #E6E8EC;
    background-color: #FCFCFD !important;
}

.dark .react-select__menu{
    width: 641px;
    border-color: #141416;
    color: #B1B5C3 !important;
    background-color: #23262F !important;
}
.light .react-select__menu{
    border-color: #E6E8EC;
    border-radius: 12px !important;
    background-color: #FCFCFD !important;}