@import "../../styles/helpers";

.title {
    @include redhat;
    text-align: start;
    font-weight: bold;
    font-size: 40px;
    // margin-bottom: 4%
    @include m {
        font-size: 30px; } }
.text {
    margin-bottom: 40px;
    @include redhat;
    font-size: 20px;
    line-height: 1.5;
    color: black;
    font-weight: 100;
    text-align: center;
    margin-top: 4%;
    @include m {
        font-size: 15px; } }
.container {
    margin-top: 2%;
    @include m {
        margin: 80px auto; } }
.card {
    flex: 0 0 calc(25% - 32px);
    max-width: calc(65% - 32px);
    margin: 15px;
    @include d {
        flex: 0 0 calc(33.333% - 32px);
        max-width: calc(33.333% - 32px); }
    @include t {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - 32px); }
    @include m {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - -132px); }
    @include a {
        flex: 0 0 calc(50% - 32px); } }
.row {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -8px 0;
    @include m {
        margin: 0 30px; }
    @include s {
        margin: 0 10px; } }
.loader {
    margin: 0 auto; }
.nav {
    display: flex;
    margin: 3% 0 4%;
    // gap: 2rem
    @include m {
        overflow: auto;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none; }
        &:before,
        &:after {
            // content: ""
            flex-shrink: 0;
            width: 32px;
            height: 1px; } } }

.link {
    flex-shrink: 0;
    margin: 0 20px 0 0;
    padding: 6px 12px;
    border-radius: 30px;
    background: none;
    font-family: 'RedHatDisplay';
    font-size: 16px;
    color: black;
    transition: all .2s;
    border: 1px solid black;
    font-weight: 100;
    @include m {
        margin: 0; }
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: #4F42C9;
        color: $neutrals8;
        border: none;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        @include m {
            margin-right: 12px; } } }
.activeLink {
    flex-shrink: 0;
    margin: 0 20px 0 0;
    padding: 6px 12px;
    border-radius: 30px;
    background: #4F42C9;
    color: $neutrals8;
    border: none;
    font-family: 'RedHatDisplay';
    font-size: 16px;
    // color: black
    transition: all .2s;
    // border: 1px solid black
    font-weight: 100;
    @include m {
        margin: 0; }
    &:hover {
        color: $neutrals8;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: #4F42C9;
        color: $neutrals8;
        border: none;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        @include m {
            margin-right: 12px; } } }
.landingImg {
    width: 100%; }
