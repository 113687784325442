@import "../../../styles/helpers";

.section {
    width: 100%;
    @include redhat;
    // margin-bottom: 12px;
    // +d
    //     min-height: 576px
    @include m {
        margin-top: 100px;
        text-align: center; }
    @include dark {
        border-color: $neutrals3; } }

.container {
    margin-top: 2%;
    @include m {
        margin: 80px auto; } }


.row {
    // borderRadius:   10px
    // --bs-gutter-x: 1.5rem;
    // --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;
    @include m {
        margin: 0 30px; }
    @include s {
        margin: 0 10px; } }

.col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ffffff00 !important;
    // margin-bottom: 1rem!important;
    // flex: 0 0 auto;
    // width: 16.66666667%
    // max-width: 90%;
    // padding-right: calc(var(--bs-gutter-x) * -2.5);
    // padding-left: calc(var(--bs-gutter-x) *  .5);
    // margin-top: var(--bs-gutter-y)
    @include redhat;
    font-weight: 600;
    font-size: 16px;
    color: #727272;
    // word-spacing: 0px;
    -webkit-font-smoothing: antialiased;
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    @include m {
        width: 50%;
        display: flex;
        justify-content: center; } }

.text {
    color: black;
    font-size: 15px;
    text-align: center;
    width: 100%;
    @include m {
        font-size: 13px; } }

.img {
    height: 120px;
    width: 120px;
    align-content: center;
    @include m {
        height: 90px;
        width: 90px;
        align-content: center; } }
.img:hover {
    color: #ffffff;
    background: #F2567A;
    box-shadow: 2px 2px 30px 0px #F2567A; }

.icon {
    background: #ffffff00 !important;
    background: #E4ECEC;
    transition: 0.7s;
    display: block;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    cursor: pointer;
    border-radius: 20px; }

.line {
    display: block;
    border: solid 2px;
    color: #EE2151;
    width: 100px;
    height: 2px;
    background: #EE2151;
    border-left: none;
    border-left-color: currentcolor;
    border-right: none;
    border-right-color: currentcolor;
    margin: 18px auto 30px;
    @include m {
        border-radius: 10px;
        width: 100px;
        margin: 0 auto;
        margin-bottom: 20px;
        transform: translateY(300%); } }


.title {
    @include redhat;
    text-align: center;
    font-weight: bold;
    font-size: 34px;
    margin-bottom: 0;
    @include m {
        font-size: 30px; } }

.title1 {
    @include redhat;
    text-align: left;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 0;
    @include m {
        font-size: 30px; } }

.nav {
    display: flex;
    margin: 4% 0;
    // gap: 2rem
    @include m {
        overflow: auto;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none; }
        &:before,
        &:after {
            // content: ""
            flex-shrink: 0;
            width: 32px;
            height: 1px; } } }

.link {
    flex-shrink: 0;
    margin: 0 20px 0 0;
    padding: 6px 12px;
    border-radius: 30px;
    background: none;
    font-family: 'RedHatDisplay';
    font-size: 16px;
    color: black;
    transition: all .2s;
    border: 1px solid black;
    font-weight: 100;
    @include m {
        margin: 0; }
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: #4F42C9;
        color: $neutrals8;
        border: none;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        @include m {
            margin-right: 12px; } } }
.button {
    margin: 5% auto 0;
    height: fit-content;
    display: block;
    padding: 10px 30px;
    font-weight: 100; }
.paginateBtns {
    display: flex;
    justify-content: flex-end;
    margin: 30px 0 20px; }
.paginateBtn {
    padding: 0px 15px;
    border: 1px solid gray;
    font-size: 18px;
    margin-right: 15px; }
.paginateBtn_1 {
    padding: 0px 10px;
    border: 1px solid gray;
    font-size: 18px;
    margin-right: 15px;
    svg {
        margin: 0 auto;
        display: block; } }
.pagination {
    text-align: right;
    margin: 3% 0;
    @include m {
        text-align: center;
        margin: 5% 0; } }
.loader {
    margin: 0 auto; }
