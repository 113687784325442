@import "../../../styles/helpers";

.section {
    padding: 35px 0;
    @include x {
        padding-top: 80px; }
    @include m {
        padding-top: 64px; } }

.container {
    padding: 0 35px;
    align-items: flex-start;
    @include t {
        display: block; } }

.preview {
    flex-shrink: 0;
    width: 352px;
    @include d {
        width: 304px; }
    @include t {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 48px;
        background: rgba($neutrals1,.9);
        overflow: auto;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        transition: all .4s;
        @include dark {
            background: rgba($neutrals2,.9); }
        &.active {
            visibility: visible;
            opacity: 1; } }
    @include m {
        padding: 32px 16px; } }


.image {
    height: 70px;
    width: 70px;
    display: block;
    margin-left: auto;
    margin-right: auto; }

.head {
    display: flex;
    margin-bottom: 40px;
    @include d {
        flex-direction: column-reverse;
        align-items: flex-start; }
    @include m {
        margin-bottom: 32px; }
    .button {
        flex-shrink: 0;
        margin-left: 24px;
        @include d {
            margin: 0 0 16px; } } }

.title {
    margin-right: auto; }

.list {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $neutrals6;
    @include m {
        margin-bottom: 32px;
        padding-bottom: 32px; }
    @include dark {
        border-color: $neutrals3; } }

.item {
    &:not(:last-child) {
        margin-bottom: 20px;
        @include m {
            margin-bottom: 32px; } } }

.note {
    margin-top: 4px;
    @include caption-2;
    color: $neutrals4; }

.file {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 182px;
    margin-top: 16px;
    border-radius: 16px;
    overflow: hidden;
    background: $neutrals7;
    @include dark {
        background: $neutrals2; } }

.load {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 400px;
    opacity: 0; }

.icon {
    margin-bottom: 10px;
    svg {
        fill: $neutrals4; } }

.format {
    @include caption-2;
    color: $neutrals4; }

.category {
    @include body-bold-2;
    text-align: center; }

.fieldset {
    margin-top: 18px;
    .field {
        &:not(:last-child) {
            margin-bottom: 18px;
            @include m {
                margin-bottom: 20px; } }
        .basic-multi-select {
            border-radius: 12px !important;
            background-color: red !important; } } }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals5; }

.row {
    display: flex;
    margin: 10px;
    @include m {
        display: block;
        margin: 0; } }

.col {
    flex: 0 0 calc(33.333% - 20px);
    width: calc(33.333% - 20px);
    margin: 0 10px;
    @include m {
        width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 20px; } } }

.options {
    margin-bottom: 40px;
    @include m {
        margin-bottom: 32px; } }

.option {
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
        margin-bottom: 32px; } }

.box {
    flex-grow: 1; }

.switch {
    flex-shrink: 0;
    margin-left: 24px; }

.text {
    margin-top: 4px;
    @include caption-2;
    color: $neutrals4;
    text-align: center; }

.foot {
    display: flex;
    align-items: center;
    @include m {
        display: block;
        text-align: center; }
    .button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 24px;
            @include m {
                margin: 0 0 12px; } } } }

.saving {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    @include button-1; }

.loader {
    margin-left: 24px; }

.error {
    font-size: 12px;
    color: red; }

.button {
    width: 100%; }
.dropdown {
    width: 641px;
    .react-select__control {
        border-radius: 12px !important;
        background-color: red !important; } }

.link {
    color: blue; }
.forgotlink {
    color: blue;
    text-align: right;
    font-size: 12px;
    margin-bottom: -18px; }
.linkhome {
    color: blue;
    text-align: center;
    font-size: 12px;
    display: flex;
    margin: 10px auto;
    width: max-content; }
.icon {
    margin-top: 1px;
    width: 11px; }
.alert {
    color: red;
    font-size: 12px;
    margin: -15px 0 10px 0px; }

.terms {
    display: flex !important;
    word-break: break-all;
    margin: 20px 0; }
.registerDiv {
    width: 500px;
    @include redhat;
    padding: 10px;
    h1 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: bold;
        @include m {
            font-size: 18px;
            text-align: start; } }
    @include m {
        width: fit-content; } }
.registerInput {
    margin-bottom: 30px; }
.registerBtn {
    display: flex;
    justify-content: space-evenly; }
.registerButton {
    padding: 9px 30px;
    font-size: 15px;
    width: fit-content;
    height: fit-content; }
.passwordShow {
    svg {
        position: absolute;
        bottom: 29.5%;
        right: 11%;
        font-size: 18px; } }
.passwordShow1 {
    svg {
        position: absolute;
        bottom: 17%;
        right: 11%;
        font-size: 18px; } }
.passwordShows {
    margin-bottom: 40px;
    svg {
        position: absolute;
        bottom: 31%;
        right: 11%;
        font-size: 18px; } }
.passwordShows1 {
    margin-bottom: 40px;
    svg {
        position: absolute;
        bottom: 20%;
        right: 11%;
        font-size: 18px; } }
.alerts {
    color: red;
    font-size: 12px;
    margin: -24px 0 10px 0px;
    position: absolute; }
