@import "../styles/helpers";

.cell {
    margin-bottom: 20px; }
.label {
    font-weight: 600;
    color: grey;
    margin-bottom: 3px; }
.head {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    margin-bottom: 20px;
    align-items: center; }
.filterIcon {
    margin-left: auto;
    // cursor: pointer
    @include m {
        display: none; } }
.filter {
    backdrop-filter: blur(3px); }
.mobfilterIcon {
    margin-left: auto;
    display: none;
    cursor: pointer;
    @include m {
        display: block; } }
.clearButton {
    display: block;
    margin: 0 auto; }
