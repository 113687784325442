@import "../../../styles/helpers";

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px 0px 0;
    @include x {
        margin: -32px -10px 0; }
    @include d {
        margin: -32px -16px 0; }
    @include m {
        display: block;
        margin: 0; } }
.nodata {
    text-align: center;
    margin-top: 78px; }
// .card
//  background: white !important;
//  font-family: 'RedHatDisplay';
//  cursor: pointer;
//  flex-basis: calc(25% - 32px);
//  max-width: calc(65% - 32px);
//  margin: 32px 14px 0px;
//  display: flex;
//  flex-direction: column
//  @include x
//      flex: 0 0 calc(33.333% - 20px)
//      width: calc(33.333% - 20px)
//      margin: 32px 10px 0
//  @include d
//      flex: 0 0 calc(50% - 32px)
//      width: calc(50% - 32px)
//      margin: 32px 16px 0
//  @include m
//      width: 100%
//      margin: 0
//      &:not(:last-child)
//          margin-bottom: 32px

.loader {
    margin: 32px auto 10px; }

.nav {
    // position: absolute
    // top: 20%
    // left: 0
    display: flex;
    // transform: translateX(-50%)
    @include t {
        // position: absolute
        top: 20%;
        left: 30%;
        display: flex; }
    @include m {
        // position: absolute
        top: 20%;
        left: 7%;
        display: flex;
        justify-content: center; } }

.link {
    margin: 0 6px;
    margin: -1px 11px;
    padding: 5px 9px;
    // border: 1px solid black
    border-radius: 14px;
    background: none;
    font-size: 14px;
    @include redhat;
    line-height: 1.14286;
    font-weight: 400;
    color: black;
    transition: all .2s;
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background:  #5048c6;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:nth-child(n+5) {
        @include d {
            display: none; } } }
.show {
    height: fit-content;
    width: fit-content;
    padding: 9px 30px;
    display: block;
    margin: 10px auto; }
.pagination {
    text-align: right; }
.items {
    width: 100%;
    @include m {
        margin-top: 20px; } }
