@import "../../styles/helpers";
.page {
    margin-top: 80px; }
.container {
    max-width: 896px;
    margin-top: -167px;
    padding: 0; }
.top {
    margin-bottom: 34px;
    @include m {
        margin-bottom: 32px;
        padding-bottom: 16px;
        border-bottom: 1px solid $neutrals6;
        @include dark {
            border-color: $neutrals3; } } }

.title {
    margin-bottom: 16px;
    text-align: center; }

.info {
    color: $neutrals4;
    strong {
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; } } }

.row {
    // display: flex
    margin: 0 -16px;
    @include d {
        display: block;
        margin: 0; } }

.col {
    // flex: 0 0 calc(50% - 32px)
    // width: calc(50% - 32px)
    margin: 0 16px;
    @include d {
        width: 100%;
        margin: 0; }
    &:not(:last-child) {
        @include d {
            margin-bottom: 64px; }
        @include m {
            margin-bottom: 32px;
            padding-bottom: 32px;
            border-bottom: 1px solid $neutrals6;
            @include dark {
                border-color: $neutrals3; } } } }

.user {
    display: flex;
    @include d {
        max-width: 416px; } }

.avatar {
    flex-shrink: 0;
    width: 128px;
    height: 128px;
    margin-right: 32px;
    @include m {
        width: 64px;
        height: 64px;
        margin-right: 16px; }
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.details {
    flex-grow: 1; }

.stage {
    margin-bottom: 8px;
    @include body-bold-2; }

.text {
    margin-bottom: 16px;
    @include caption-2;
    color: $neutrals4; }

.file {
    display: inline-block;
    position: relative;
    overflow: hidden; }

.load {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 140px;
    opacity: 0; }

.list {
    margin-bottom: 40px;
    @include d {
        display: flex;
        margin: 0 -20px 64px; }
    @include m {
        display: block;
        margin: 0 0 32px; } }

.item {
    @include d {
        flex: 0 0 calc(50% - 40px);
        width: calc(50% - 40px);
        margin: 0 20px; }
    @include m {
        width: 100%;
        margin: 0; }
    & > .button {
        margin-top: 32px; }
    &:not(:last-child) {
        margin-bottom: 40px;
        @include d {
            margin-bottom: 0; }
        @include m {
            margin-bottom: 32px; } } }

.category {
    margin-bottom: 32px;
    @include body-bold-2; }

.fieldset {
    & > .field {
        border: 1px solid black;
        &:not(:last-child) {
            margin-bottom: 32px; } } }

.box {
    position: relative;
    .button {
        cur {}
        position: absolute;
        right: 12px;
        bottom: 8px;
        height: 32px;
        padding: 0 10px; } }

.note {
    color: $neutrals4; }

.btns {
    display: flex;
    justify-content: center;
    // margin-top: 40px
    // padding-top: 40px
    // border-top: 1px solid $neutrals6
    @include m {
        display: block;
        text-align: center; }
    @include dark {
        border-color: $neutrals3; }
    .button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 32px;
            @include m {
                margin: 0 0 32px; } } } }

.clear {
    display: inline-flex;
    align-items: center;
    @include button-1;
    color: $neutrals4;
    transition: color .2s;
    svg {
        margin-right: 8px;
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        color: $cyan;
        svg {
            fill: $cyan; } } }
.editname {
    border: 1px solid #655c5ca6;
    height: 36px;
    width: 202px;
    border-radius: 8px;
    margin-left: 12px;
    padding: 5px 10px; }
.editname1 {
    border: 1px solid #655c5ca6;
    height: 36px;
    width: 202px;
    border-radius: 8px;
    margin-left: 12px;
    padding: 5px 10px; }
.edit {
    display: flex;
    align-items: center; }
.lastname {
    @include redhat;
    margin-left: 31px;
    font-size: 15px;
    font-weight: 400 {
     color: #000000; } }
.editname2 {
    border: 1px solid #655c5ca6;
    height: 36px;
    width: fit-content;
    border-radius: 8px;
    margin-left: 46px;
    padding: 5px 10px; }
.name {
    display: flex;
    margin-top: 16px;
    align-items: center; }
.heading {
    @include redhat;
    font-size: 15px;
    font-weight: 400;
    color: #000000; }
.headings {
    @include redhat;
    font-size: 15px;
    font-weight: 400;
    color: #000000;
    margin-left: 20px; }
.editname_2 {
    border: 1px solid #655c5ca6;
    height: 36px;
    width: fit-content;
    border-radius: 8px;
    margin-left: 13px;
    padding: 5px 10px; }
.mobno {
    display: flex;
    margin-top: 16px; }

