@import "../../styles/helpers";

.card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background: white !important;
    height: 330px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    flex-direction: column;
    border: solid 1px #EE2151;
    border-radius: 12px;
    transition: 0.7s all ease-in-out;
    @include m {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      background: white !important;
      height: 230px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 25px;
      flex-direction: column;
      border: solid 1px #F2567A;
      border-radius: 12px;
      transition: 0.7s all ease-in-out; } }

.card:hover {
    transform: scale(1.1);
    background: #F2567A;
    box-shadow: 5px 5px 30px 0px #F2567A; }

.preview {
    height: 70%;
    width: 100%;
    border-radius: 12px; }

.category {
    position: absolute;
    top: 11px;
    left: 8px; }

.avatarWrapper {
  position: relative;
  top: 250px;
  left: 38%;
  width: 60px;
  height: 60px;
  margin-top: -40px;
  @include m {
    position: relative;
    top: 165px;
    left: 34%;
    width: 60px;
    height: 60px;
    margin-top: -70px; } }


.avatar {
  align-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%; }

.icon {
  position: absolute;
  right: 2px;
  bottom: 4px;
  border-radius: 50%;
  background: #EE2151;
  width: 15px;
  height: 15px;
  display: grid;
  text-align: center;
  line-height: 15px;
  color: white;
  font-size: 10px;
  font-weight: bolder; }

.counter {
    text-align: center;
    font-size: 15px;
    margin-top: 30px;
    font-weight: 700;
    @include redhat;
    @include d {
      font-size: 12px;
      margin-top: 40px;
      font-weight: 700;
      @include redhat; }
    @include m {
      font-size: 12px;
      margin-top: 28px;
      font-weight: 700;
      @include redhat; } }


